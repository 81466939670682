import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import SiteNavbar from "components/Navbars/SiteNavbar.js";
import HomeHeader from "components/Home/HomeHeader.js";

// sections for this page
import PlatformImages from "../components/Home/PlatformImages.js";
import Partners from "../components/Home/Partners.js";
import Explanation from "./index-sections/Explanation.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import Subscribe from "../components/Home/Subscribe.js";
import Introduction from "components/Home/Introduction.js";
import GetInTouch from "components/Home/GetInTouch.js";
import ProjectStages from "components/Home/ProjectStages.js";
import { Calculate } from "@mui/icons-material";
import Calculators from "components/Home/Calculators.js";

function HomePage() {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <SiteNavbar />
      <div className="wrapper">
        <HomeHeader />
        <div className="main">
          <Introduction />
          <Explanation />
          {/* <Partners /> */}
          <Calculators />
          <ProjectStages />
          {/* <PlatformImages /> */}
          {/* <GetInTouch />   */}
          <Subscribe />
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default HomePage;
