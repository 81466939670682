import * as React from 'react';

import {
    Row,
    Col,
} from "reactstrap";
import Map from './Map';
import { useTranslation } from "react-i18next";
import { TextField } from '@mui/material';

function Location(props) {

    const google = '';
    const { t } = useTranslation();

    return (
        <>
            <Row className="title justify-content-center">
                <h3 className="title text-center">
                    {t("calculatorSteps.location.title")}
                </h3>
            </Row>
            <Row className='basic-calculator-steps-body location'>
                <Col className='location-input' xs="12" sm="12" md="7" lg="7" xl="7">
                    <Map
                        center={{ lat: 44.80247547024257, lng: 20.466289054354935 }}
                        height='100%'
                        width='100%'
                        zoom={8}
                        setLocation={props.setLocation}
                    />
                </Col>
                <Col className='location-description' xs="12" sm="12" md="5" lg="5" xl="5">
                    <h5>
                        {t("calculatorSteps.location.description")}
                    </h5>
                    <TextField
                        label={t("calculatorSteps.location.inputFieldLabel")}
                        key={props.getLocation()}
                        defaultValue={props.getLocation()}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Col>
            </Row>
        </>
    );

}
export default Location;