import React from 'react';

function EnterpriseCalculatorsPage(props) {
    return (
        <div>
            <h2>
            Enterprise Calculators Page
                </h2>
                <div>
                    <a href='/enterprise/households-calculator'>Households calculator</a>
                    <br/>
                    <a href='/enterprise/industry-calculator'>Enterprise calculator</a>
                </div>
        </div>
    );
}

export default EnterpriseCalculatorsPage;