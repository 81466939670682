import React from "react";

// reactstrap components
import {
    Button,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Row,
    Col,
    Progress
} from "reactstrap";

import AverageBill from "../CalculatorSteps/AverageBill";
import HouseSurface from "../CalculatorSteps/HouseSurface";
import Location from "../CalculatorSteps/Location";
import RoofOrientation from "../CalculatorSteps/RoofOrientation";
import RooftopSlope from "../CalculatorSteps/RooftopSlope";
import { useTranslation } from "react-i18next";
import MainService from "services/MainService.js";
import Country from "components/CalculatorSteps/Country";
import HouseholdsResults from "./HouseholdsResults";
import { t } from "i18next";

function HouseholdsCalculatorSteps() {

    const { t } = useTranslation();

    const [country, setCountry] = React.useState("rs");

    const [pills, setPills] = React.useState("1");
    const [latitude, setLatitude] = React.useState(null);
    const [longitude, setLongitude] = React.useState(null);
    const [surface, setSurface] = React.useState(null);
    const [aspect, setAspect] = React.useState(null);
    const [angle, setAngle] = React.useState(null);
    const [consumption, setConsumption] = React.useState(null);

    React.useEffect(() => {
        document.body.classList.add("basic-calculator");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("basic-calculator");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    function setLocation(latitude, longitude) {
        setLatitude(latitude);
        setLongitude(longitude);
    }

    function getLocation() {
        if (latitude == null || longitude == null) return t("calculatorSteps.location.inputFieldText");
        return MainService.degToDMS(latitude) + ", " + MainService.degToDMS(longitude);
    }

    function allowNextStep(tabPill) {
        switch (parseInt(tabPill)) {
            case 1:
                return latitude != null && longitude != null;
            case 2:
                return true;
            case 3:
                return true;
            case 4:
                return surface != null && surface != "";
            case 5:
                return consumption != null && consumption != "";
            default:
                return false;
        }
    }

    function changeStep(tabPill) {
        setPills(tabPill);
    }

    function pillsProgres(tabPill) {
        if (tabPill == pills) return "active";
        if (tabPill < pills) return "completed";
        return "";
    }

    return (
        <>
            <Country
                country={country}
                setCountry={setCountry}
                pills={pills}
            />
            <Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <h2 className="title text-center">
                        {t("householdCalculator.steps")}
                    </h2>
                    <div className="nav-align-center">
                        <Nav
                            className="nav-pills-info nav-pills-just-icons"
                            pills
                            role="tablist"
                        >
                            <NavItem>
                                <NavLink
                                    className={pillsProgres("1")}
                                    onClick={(e) => {
                                        if ("1" < pills) {
                                            e.preventDefault();
                                            changeStep("1");
                                        }
                                    }}
                                    style={{ padding: "10px" }}
                                >
                                    <i className="now-ui-icons location"></i>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={pillsProgres("2")}
                                    onClick={(e) => {
                                        if ("2" < pills) {
                                            e.preventDefault();
                                            changeStep("2");
                                        }
                                    }}
                                    style={{ padding: "10px" }}
                                >
                                    <i className="now-ui-icons orientation"></i>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={pillsProgres("3")}
                                    onClick={(e) => {
                                        if ("3" < pills) {
                                            e.preventDefault();
                                            changeStep("3");
                                        }
                                    }}
                                    style={{ padding: "10px" }}
                                >
                                    <i className="now-ui-icons rooftops-slope"></i>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={pillsProgres("4")}
                                    onClick={(e) => {
                                        if ("4" < pills) {
                                            e.preventDefault();
                                            changeStep("4");
                                        }
                                    }}
                                    style={{ padding: "10px" }}
                                >
                                    <i className="now-ui-icons house-surface-area"></i>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={pillsProgres("5")}
                                    onClick={(e) => {
                                        if ("5" < pills) {
                                            e.preventDefault();
                                            changeStep("5");
                                        }
                                    }}
                                    style={{ padding: "10px" }}
                                >
                                    <i className="now-ui-icons average-bill"></i>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <div className="progress-container progress-info">
                            <Progress max="100" value={(pills - 1) * 20} color={pills === 6 ? "success" : ""}>
                                <span className="progress-value">{(pills - 1) * 20}%</span>
                            </Progress>
                        </div>
                    </div>
                </Col>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <TabContent className="gallery" activeTab={"pills" + pills}>
                        <TabPane tabId="pills1">
                            <div className="basic-calculator-steps-card" style={{ minHeight: "600px" }}>
                                <Location setLocation={setLocation} getLocation={getLocation} />
                            </div>

                            <Row className="basic-calculator-steps-buttons">
                                <PreviousNextButtons changePill={changeStep} currentPill={pills} allowNextStep={allowNextStep} />
                            </Row>
                        </TabPane>
                        <TabPane tabId="pills2">
                            <div className="basic-calculator-steps-card">
                                <RoofOrientation setAspect={setAspect} />
                            </div>

                            <Row className="basic-calculator-steps-buttons">
                                <PreviousNextButtons changePill={changeStep} currentPill={pills} allowNextStep={allowNextStep} />
                            </Row>
                        </TabPane>
                        <TabPane tabId="pills3">
                            <div className="basic-calculator-steps-card">
                                <RooftopSlope setAngle={setAngle} />
                            </div>

                            <Row className="basic-calculator-steps-buttons">
                                <PreviousNextButtons changePill={changeStep} currentPill={pills} allowNextStep={allowNextStep} />
                            </Row>
                        </TabPane>
                        <TabPane tabId="pills4">
                            <div className="basic-calculator-steps-card">
                                <HouseSurface setSurface={setSurface} />
                            </div>
                            <Row className="basic-calculator-steps-buttons">
                                <PreviousNextButtons changePill={changeStep} currentPill={pills} allowNextStep={allowNextStep} />
                            </Row>
                        </TabPane>
                        <TabPane tabId="pills5">
                            <div className="basic-calculator-steps-card">
                                <AverageBill setConsumption={setConsumption} />
                            </div>
                            <Row className="basic-calculator-steps-buttons">
                                <PreviousNextButtons changePill={changeStep} currentPill={pills} allowNextStep={allowNextStep} />
                            </Row>
                        </TabPane>
                        <TabPane tabId="pills6" className="results">
                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <HouseholdsResults
                                    start={pills == 6}
                                    inputValues={pills == 6
                                        ? {
                                            latitude: latitude,
                                            longitude: longitude,
                                            surface: surface,
                                            aspect: aspect,
                                            angle: angle,
                                            consumption: consumption
                                        }
                                        : {}}
                                    country={country}
                                />
                            </Col>
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </>
    );

}

function PreviousNextButtons(props) {

    return (
        <div className="float-right">
            {
                props.currentPill == "1"
                    ? <></>
                    : <Button
                        className="basic-calculator-steps-button btn-round"
                        color="info"
                        type="button"
                        onClick={() => props.changePill(parseInt(props.currentPill) - 1)}
                        style={{ float: "left" }}
                    >
                        <i className="now-ui-icons arrows-1_minimal-left"></i>
                        {t("stringsTranslations.previousStep")}
                    </Button>
            }
            {
                props.currentPill == "5"
                    ? <Button
                        disabled={!props.allowNextStep(props.currentPill)}
                        className="basic-calculator-steps-button btn-round"
                        color="info"
                        type="button"
                        onClick={() => props.changePill(parseInt(6))}
                        style={{ float: "right" }}
                    >
                        {t("stringsTranslations.finish")}
                        <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </Button>
                    : <Button
                        disabled={!props.allowNextStep(props.currentPill)}
                        className="basic-calculator-steps-button btn-round"
                        color="info"
                        type="button"
                        onClick={() => props.changePill(parseInt(props.currentPill) + 1)}
                        style={{ float: "right" }}
                    >
                        {t("stringsTranslations.nextStep")}
                        <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </Button>
            }
        </div>
    );

}

export default HouseholdsCalculatorSteps;
