import axios from 'axios';

class EmailService {

    subscribe(data){
        return axios.post("https://solarlink.app/python-calculators/subscribe", 
            {
                "email": data.email,
                "type": data.type,
            }
        );
    }
    
}

export default new EmailService;