import React from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

// core components

function CompleteExamples() {
  return (
    <>
      <div className="section">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col lg="6" md="6">
              <h2 className="title">Domaćinstva</h2>
              <h5 className="description">
              Solarlink kalkulator namenjen domaćinstvima omogućava precizan proračun adekvatne snage solarne elektrane za potrebe Vašeg domaćinstva, po principu „kWh za kWh“, sa predajom viška električne energije u elektrodistributivni sistem.
              </h5>
              <h5 className="description">
Saznaćete i koju količinu energije će elektrana proizvoditi, optimalnu cenu investicije, period isplativosti, kao i druge informacije od značaja za takav projekat.
              </h5>
              <Button
                block
                className="btn-round btn-faqs"
                color="info"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                size="lg"
              >
                Kalkulator za domaćinstva
              </Button>
            </Col>
            <Col lg="6" md="6">
              <h2 className="title">Privreda</h2>
              <h5 className="description">
              Solarlink kalkulator za industriju je namenjen privrednim subjektima i omogućava precizan proračun adekvatne snage solarne elektrane, po principu finansijske kompenzacije, sa predajom viška električne energije u elektrodistributivni sistem.
              </h5>
              <h5 className="description">
Saznaćete i koju količinu energije će elektrana proizvoditi, optimalnu cenu investicije, period isplativosti, kao i druge informacije od značaja za takav projekat.
              </h5>
              <Button
                block
                className="btn-round btn-contact"
                color="info"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
                size="lg"
              >
                Contact
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default CompleteExamples;
