import React from "react";
import backgroundImageContactUs from "../../assets/img/contact-us-page-header.png"

function ContactUsHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        className="page-header page-header-small"
        style={{minHeight: "40vh"}}
      >
        <div
          className="page-header-image"
          ref={pageHeader}
          style={{
            backgroundImage: `url(${backgroundImageContactUs})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            minHeight: "40vh"
          }}
        ></div>
      </div>
    </>
  );

}

export default ContactUsHeader;
