import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

import {
    Row,
    Col,
} from "reactstrap";
import { useTranslation } from 'react-i18next';

function HouseSurface(props) {

    const { t } = useTranslation();

    const [values, setValues] = React.useState({
        surface: '',
    });

    const handleChange = (prop) => (event) => {
        props.setSurface(event.target.value);
        setValues({ ...values, [prop]: event.target.value });
    };

    return (
        <>
            <Row className="justify-content-center">
                <h3 className="title text-center">
                    {t('calculatorSteps.freeRoofArea.title')}
                </h3>
            </Row>
            <Row className='basic-calculator-steps-body'>
                <Col className='house-surace-input' xs="12" sm="12" md="4" lg="4" xl="4">
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <img
                            alt="house-surface"
                            src={require("assets/img/basic calculator/house-surface.png")}
                            style={{ maxWidth: "250px" }}
                        ></img>
                    </Box>
                </Col>
                <Col className='house-surace-description' xs="12" sm="12" md="8" lg="8" xl="8" style={{ flexWrap: "wrap", flexDirection: "row-reverse" }}>
                    <h5>
                    {t('calculatorSteps.freeRoofArea.description')}
                    </h5>

                    <FormControl sx={{ m: 1 }}>
                        <InputLabel htmlFor="outlined-adornment-surface">{t('calculatorSteps.freeRoofArea.inputFieldText')}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-surface"
                            value={values.amount}
                            onChange={handleChange('surface')}
                            endAdornment={<InputAdornment position="end">m<sup>2</sup></InputAdornment>}
                            label="adornment-surface"
                            type="number"
                        />
                    </FormControl>
                </Col>
            </Row>
        </>

    );

}
export default HouseSurface;