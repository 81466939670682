import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    Row,
    Col,
    Button
} from "reactstrap";
import { Box, LinearProgress } from '@mui/material';
import MainService from 'services/MainService';
import CashFlowGraph from 'components/HouseholdsCalculator/Results/CashFlowGraph';
import ProductionConsumptionGraph from 'components/Graphs/ProductionConsumptionGraph';
import { useTranslation } from 'react-i18next';
import CalculatorService from 'services/CalculatorService';
import { PDFDocument, StandardFonts } from 'pdf-lib';


import PDFService from 'services/PDFService';
import ResultsModal from 'components/HouseholdsCalculator/Results/ResultsModal';

import industryReportSerbianPDF from '../../assets/pdf-templates/industry-report-serbian.pdf'
import industryReportFrenchPDF from '../../assets/pdf-templates/industry-report-french.pdf'
import industryReportEnglishPDF from '../../assets/pdf-templates/industry-report-english.pdf'

const data = {
    "inputs": {
        "lat": 44.72977943768759, "lon": 20.032109152278235, "roof_area": "100", "aspect": 0, "angle": 10,
        "monthlyConsumption": {
            "january": "1", "february": "2", "march": "32", "april": "400", "may": "46", "june": "7", "july":
                "53", "august": "5465", "september": "75", "october": "745", "november": "3", "december": "6"
        },
        "maximumAuthorizedPower": "676", "workingHours": {
            "monday": "0900-1500", "tuesday": "0900-1500", "wednesday":
                "0900-1500", "thursday": "0900-1500", "friday": "0900-1500", "saturday": "0900-1200", "sunday": "null"
        },
        "annualVacationData": [{ "month": "april", "days": "5" }]
    },
    "results": {
        "broj_radnih_sati": {
            "January": 150, "February":
                132, "March": 141, "April": 144, "May": 150, "June": 135, "July": 150, "August": 147, "September": 138, "October": 150,
            "November": 141, "December": 144
        }, "mesecna_proizvodnja": [{ "month": "January", "E_m": 556.4868243174603 }, {
            "month":
                "February", "E_m": 709.4246530929706
        }, { "month": "March", "E_m": 1360.629495038549 }, {
            "month": "April", "E_m":
                1741.1269917505672
        }, { "month": "May", "E_m": 2029.2707271247173 }, { "month": "June", "E_m": 2146.892477564626 }, {
            "month":
                "July", "E_m": 2334.407554585034
        }, { "month": "August", "E_m": 2073.896064603175 }, {
            "month": "September", "E_m":
                1548.2923380770978
        }, { "month": "October", "E_m": 1167.0560112743767 }, { "month": "November", "E_m": 767.4966982222223 },
        { "month": "December", "E_m": 492.06084040816336 }], "snaga": 14.77660181405896, "preporucena_snaga": 14.77660181405896,
        "iskoriscena_povrsina_krova": 73.8830090702948, "godisnja_proizvodnja": [17182718.83120241, 17088213.877630796,
            16994228.70130383, 16900760.44344666, 16807806.261007704, 16715363.326572163, 16623428.828276018, 16531999.9697205,
            16441073.969887037, 16350648.06305266, 16260719.498705871, 16171285.54146299, 16082343.470984943, 15993890.581894526,
            15905924.183694107, 15818441.600683792, 15731440.171880031, 15644917.250934692, 15558870.206054552, 15473296.419921253,
            15388193.289611686, 15303558.226518823, 15219388.65627297, 15135682.01866347, 15052435.767560821, 14969647.370839238,
            14887314.310299624, 14805434.081592977, 14724004.194144217], "ukupna_proizvodnja_u_eksploatacionom_periodu":
            461763029.1138204, "godisnja_usteda": [970.9077986857853, 985.2732712173607, 985.2732712173607, 985.2732712173607,
                985.2732712173607, 985.2732712173607, 985.2732712173607, 985.2732712173607, 985.2732712173607, 985.2732712173607,
                985.2732712173607, 985.2732712173607, 985.2732712173607, 985.2732712173607, 985.2732712173607, 985.2732712173607,
                985.2732712173607, 985.2732712173607, 985.2732712173607, 985.2732712173607, 985.2732712173607, 985.2732712173607,
                985.2732712173607, 985.2732712173607, 985.2732712173607, 985.2732712173607, 985.2732712173607, 985.2732712173607,
                985.2732712173607], "ukupna_usteda_u_eksploatacionom_periodu": 28558.559392771876, "godisnji_visak":
            [17181747.923403706, 17435967.66308672, 17435967.66308672, 17435967.66308672, 17435967.66308672, 17435967.66308672,
                17435967.66308672, 17435967.66308672, 17435967.66308672, 17435967.66308672, 17435967.66308672, 17435967.66308672,
                17435967.66308672, 17435967.66308672, 17435967.66308672, 17435967.66308672, 17435967.66308672, 17435967.66308672,
                17435967.66308672, 17435967.66308672, 17435967.66308672, 17435967.66308672, 17435967.66308672, 17435967.66308672,
                17435967.66308672, 17435967.66308672, 17435967.66308672, 17435967.66308672, 17435967.66308672],
        "ukupni_visak_u_eksploatacionom_periodu": 505388842.4898317, "ukupni_godisnji_visak": 17532395.840207864,
        "godisnja_usteda_kwh": 990.7222435569238, "godisnja_usteda_eur": 1402740.2755531627,
        "ukupna_usteda_u_eksploatacionom_periodu_eur": 40435391.183095455, "cena_godisnjeg_odrzavanja": 258.5905317460318,
        "ukupna_usteda_co2_u_eksploatacionom_periodu": 392498.5747467473, "godisnji_visak_u_procentima": 99.99434951006158,
        "godisnja_usteda_u_procentima": 14.494838969377085, "roi": 0.9905566416616772, "stanje": [-12929.52658730159,
            1382095.6774503186, 2777120.8814879386, 4172146.0855255593, 5567171.28956318, 6962196.4936008, 8357221.697638419,
            9752246.90167604, 11147272.10571366, 12542297.30975128, 13937322.5137889, 15332347.71782652, 16727372.921864139,
            18122398.12590176, 19517423.32993938, 20912448.533977002, 22307473.738014624, 23702498.942052245, 25097524.146089867,
            26492549.35012749, 27887574.55416511, 29282599.75820273, 30677624.962240353, 32072650.166277975, 33467675.370315596,
            34862700.57435322, 36257725.77839084, 37652750.98242846, 39047776.18646608]
    }
}

function IndustryCalculatorResults(props) {

    const { t } = useTranslation();

    /**
     * Production Consumption Graph = PCG
     * Cash Flow Graph = CFG
     */

    const [phase, setPhase] = useState("none");
    const [pcgPNG, setPcgPNG] = useState(null);
    const [cfgPNG, setCfgPNG] = useState(null);
    const [recievedData, setRecievedData] = useState(null);

    useEffect(() => {

        // if (props.start && phase == "none") {

        //     setPhase("calculating");

        //     CalculatorService.industryCalculation(props.inputValues)
        //         .then((response) => {
        //             alert("stiglo");

        //             console.log("====================================");
        //             console.log("IndustryCalculatorResults.js");
        //             console.log("====================================");
        //             console.log(response);

        //             setRecievedData(response.data);
        //             setPhase("finished");
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         })

        //     // setRecievedData(data);
        //     // console.log("====================================");
        //     // console.log("IndustryCalculatorResults.js");
        //     // console.log("====================================");
        //     // console.log(data);
        // }

    });

    const startCalculator = () => {

        setPhase("calculating");

        CalculatorService.industryCalculation(props.inputValues)
            .then((response) => {
                setRecievedData(response.data);
                setPhase("finished");
            })
            .catch((error) => {
                console.log(error);
            })

    }

    const getPDFTemplate = (country) => {
        switch (country) {
            case "rs":
                // return industryReportSerbianPDF;
                return industryReportEnglishPDF;
            case "fr":
                return industryReportFrenchPDF;
            case "gb":
                return industryReportEnglishPDF;
        }
    }

    async function generatePDF(investitor) {

        const formPdfBytes = await fetch(getPDFTemplate(props.country)).then(res => res.arrayBuffer());

        // Load a PDF with form fields
        const pdfDoc = await PDFDocument.load(formPdfBytes);

        // Set all available metadata fields on the PDFDocument. Note that these fields
        // are visible in the "Document Properties" section of most PDF readers.
        pdfDoc.setTitle(investitor)
        pdfDoc.setAuthor(investitor)
        pdfDoc.setSubject('Cost effectiveness study')
        pdfDoc.setKeywords(['solarlink', 'solar energy', 'calculation', 'report'])
        pdfDoc.setProducer('Solarlink - PDF Generator')
        pdfDoc.setCreator('Solarlink - https://solarlink.app')
        pdfDoc.setCreationDate(new Date())
        pdfDoc.setModificationDate(new Date())

        // Get Form
        const form = pdfDoc.getForm();

        let data = PDFService.industryCalculatorPrepareData(recievedData, investitor);
        PDFService.industryCalculatorFillTemplate(form, data);

        const imagePCG = await pdfDoc.embedPng(pcgPNG);
        const imageCFG = await pdfDoc.embedPng(cfgPNG);

        // Set Production Consumption graph
        const productionConsumptionField = form.getButton('energyGraphField');
        productionConsumptionField.setImage(imagePCG)

        // Set Cash Flow graph
        const cashFlowField = form.getButton('cashflowGraphField');
        cashFlowField.setImage(imageCFG)

        // Zakljucava polja forme da ne mogu da se menjaju
        form.flatten();

        // Save the modified PDF to a new buffer
        const pdfBytes = await pdfDoc.save();

        const bytes = new Uint8Array(pdfBytes);
        const blob = new Blob([bytes], { type: "application/pdf" });
        const docUrl = URL.createObjectURL(blob);

        window.open(docUrl);

    };

    return (
        <>

            <div id="basic-calculator-inputs">

                <Row className="justify-content-center">
                    <h3 className="title text-center">
                        {t("stringsTranslations.inputData")}
                    </h3>
                </Row>

                <Row>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                        <TableContainer className='input-data' component={Paper}>
                            <Table aria-label="caption table">
                                {/* <caption>* Shouldn't be displayed. Displaying only for testing purposes</caption> */}
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>{t("stringsTranslations.inputData")}</b></TableCell>
                                        <TableCell align="right"><b>{t("stringsTranslations.values")}</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key="coordinates">
                                        <TableCell component="th" scope="lat">
                                            {t("stringsTranslations.coordinates")}
                                        </TableCell>
                                        <TableCell align="right">
                                            {MainService.degToDMS(props.inputValues["latitude"])}, {MainService.degToDMS(props.inputValues["longitude"])}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="aspect">
                                        <TableCell component="th" scope="row">
                                            {t("stringsTranslations.azimuthAngle")}
                                        </TableCell>
                                        <TableCell align="right">
                                            {props.inputValues["aspect"]}°
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="angle">
                                        <TableCell component="th" scope="row">
                                            {t("stringsTranslations.tiltAngle")}
                                        </TableCell>
                                        <TableCell align="right">
                                            {props.inputValues["angle"]}°
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="roof_area">
                                        <TableCell component="th" scope="row">
                                            {t("stringsTranslations.freeRoofArea")}
                                        </TableCell>
                                        <TableCell align="right">
                                            {props.inputValues["surface"]}m²
                                        </TableCell>
                                    </TableRow>
                                    <TableRow key="maximumAuthorized_power">
                                        <TableCell component="th" scope="row">
                                            {t("stringsTranslations.maximumAuthorizedPower")}
                                        </TableCell>
                                        <TableCell align="right">
                                            {props.inputValues["maximumAuthorizedPower"]} kW
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TableContainer className='working-hours' component={Paper} >
                            <Table sx={{ minWidth: 350 }} aria-label="caption table">
                                {/* <caption>* Shouldn't be displayed. Displaying only for testing purposes</caption> */}
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>{t("stringsTranslations.workingDays")}</b></TableCell>
                                        <TableCell align="right"><b>{t("stringsTranslations.workingHours")}</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        props.inputValues["workingHours"] != undefined &&
                                        MainService.getDaysInWeek().map((day) => (
                                            <TableRow key={day} >
                                                <TableCell component="th" scope="row">
                                                    {t("daysOfTheWeek." + day)}
                                                </TableCell>
                                                <TableCell align="right">{props.inputValues["workingHours"][day] != "null" ? MainService.convertTimeFormat(props.inputValues["workingHours"][day]) : t("stringsTranslations.nonWorkingDay")}</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="6" xl="6">
                        <TableContainer className='monthly-consumption' component={Paper} >
                            <Table sx={{ minWidth: 350 }} aria-label="caption table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>{t("stringsTranslations.month")}</b></TableCell>
                                        <TableCell align="right"><b>{t("stringsTranslations.energyConsumption")}</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        props.inputValues["monthlyConsumption"] != undefined &&
                                        MainService.getMonths().map((m) => (
                                            <TableRow key={m}>
                                                <TableCell component="th" scope="row">
                                                    {m}
                                                </TableCell>
                                                <TableCell align="right">{props.inputValues["monthlyConsumption"][m]}kWh</TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Col>
                </Row>

                <div className="start-calculator-button">
                    {
                        phase == "calculating"
                            ? <CircularProgress />
                            : <h5>
                                {t("stringsTranslations.startCalculator")}
                                <Button
                                    className="start-industry-calculator btn-round"
                                    color="info"
                                    type="button"
                                    onClick={startCalculator}
                                >
                                    <i className="now-ui-icons files_single-copy-04"></i>
                                </Button>
                            </h5>
                    }
                </div>

            </div>

            {
                phase == "finished" && recievedData != null &&
                <div id="basic-calculator-results">
                    <Row className="justify-content-center">
                        <h3 className="title text-center">
                            {t("stringsTranslations.results")}
                        </h3>
                        <div className='conclusion'>
                            {
                                CalculatorService.getConclusionTextInudstryCalculator(recievedData.results.zakljucak, recievedData)
                            }
                        </div>
                    </Row>

                    {/* <Row className="justify-content-center"> */}
                    <div>
                        <Row className='production-consumption-graph' style={{ marginTop: "50px" }}>
                            {/* <Col xs="12" sm="12" md="12" lg="6" xl="6" className='cash-flow-graph'> */}
                            <CashFlowGraph
                                data={MainService.prepareDataForCashFlowDiagram(recievedData["results"]["stanje"])}
                                setPNG={setCfgPNG}
                            />
                            {/* </Col> */}
                        </Row>

                        <Row className='production-consumption-graph' style={{ marginTop: "50px" }}>
                            <ProductionConsumptionGraph
                                production={recievedData["results"]["mesecna_proizvodnja"]}
                                consumption={recievedData["inputs"]["monthlyConsumption"]}
                                setPNG={setPcgPNG}
                            />
                        </Row>
                    </div>
                    {/* </Row> */}

                    {
                        pcgPNG != undefined && cfgPNG != undefined &&
                        <ResultsModal generatePDF={generatePDF} />
                    }

                </div>
            }
        </>
    );
}

export default IndustryCalculatorResults;