
import { t } from 'i18next';
class MainService {

    degToDMS(deg) {
        var d = Math.floor(deg);
        var minfloat = (deg - d) * 60;
        var m = Math.floor(minfloat);
        var secfloat = (minfloat - m) * 60;
        var s = Math.round(secfloat);
        // After rounding, the seconds might become 60. These two
        // if-tests are not necessary if no rounding is done.
        if (s == 60) {
            m++;
            s = 0;
        }
        if (m == 60) {
            d++;
            m = 0;
        }
        return ("" + d + "° " + m + "' " + s + '"');
    }

    getMonths() {
        return [
            "january",
            "february",
            "march",
            "april",
            "may",
            "june",
            "july",
            "august",
            "september",
            "october",
            "november",
            "december"
        ]
    }

    getDaysInWeek() {
        return [
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
            "sunday"
        ]
    }

    getDaysInMonth(month) {
        switch (month) {
            case "january":
                return 31
            case "february":
                return 28
            case "march":
                return 31
            case "april":
                return 30
            case "may":
                return 31
            case "june":
                return 30
            case "july":
                return 31
            case "august":
                return 31
            case "september":
                return 30
            case "october":
                return 31
            case "november":
                return 30
            case "december":
                return 31
            default:
                return 0;
        }
    }

    getAvailableCountriesForCalculator() {
        return [
            { "value": "rs", "label": t("countries.serbia") },
            { "value": "fr", "label": t("countries.france") },
            { "value": "gb", "label": t("countries.greatBritain") }
        ]
    }

    // Used for industry calculator to calculate working hours from 6AM to 10PM
    countWorkingHours(fromHours, fromMinutes, toHours, toMinutes) {

        if (fromHours < 6) {
            fromHours = 6;
            fromMinutes = 0;
        }

        if (toHours > 22) {
            toHours = 22;
            toMinutes = 0;
        }

        let hoursDiff = toHours - fromHours;

        if (fromMinutes > toMinutes) {
            hoursDiff--;
            toMinutes += 60;
        }

        let minutesDiff = toMinutes - fromMinutes;

        return hoursDiff + minutesDiff / 60;
    }

    prepareDataForCashFlowDiagram(data) {
        let out = [];
        let thisYear = new Date().getFullYear();
        for (let i = 0; i < data.length; i++) {
            out.push({ "EUR": data[i], "year": thisYear + i });
        }
        return out;
    }

    roundNumber(number, decimals) {
        return Math.round(number * Math.pow(10, decimals)) / Math.pow(10, decimals);
    }

    getTodayDate() {
        const date = new Date();

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }

    convertTimeFormat(timeStr) {

        if(timeStr == null || timeStr == "null") return "Neradan dan";
    
        const parts = timeStr.split('-');
        if (parts.length !== 2) {
            throw new Error('Invalid time format');
        }
    
        const formatPart = (part) => {
            if (part.length !== 4) {
                throw new Error('Invalid time part format');
            }
            return part.slice(0, 2) + ':' + part.slice(2);
        };
    
        return formatPart(parts[0]) + ' - ' + formatPart(parts[1]) + 'h';
    }

}

export default new MainService;