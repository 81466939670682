import React, { useEffect, useState } from 'react';
import Slider from '@mui/material/Slider';

import {
    Row,
    Col,
} from "reactstrap";
import { FormControl, InputLabel, OutlinedInput, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

function RoofOrientation(props) {

    const { t } = useTranslation();

    const [angle, setAngle] = useState(0);

    const marks = [
        {
            value: 180,
            label: 'N',
        },
        {
            value: 135,
            label: 'NW',
        },
        {
            value: 90,
            label: 'W',
        },
        {
            value: 45,
            label: 'SW',
        },
        {
            value: 0,
            label: 'S',
        },
        {
            value: -45,
            label: 'SE',
        },
        {
            value: -90,
            label: 'E',
        },
        {
            value: -135,
            label: 'NE',
        },
        {
            value: -180,
            label: 'N',
        },
    ];

    function valuetext(value) {
        return `${value}°`;
    }

    useEffect(() => {
        props.setAspect(angle);
    }, []);

    function handleChange(e) {
        setAngle(e.target.value);
        props.setAspect(e.target.value);
    }

    return (
        <>
            <Row className="justify-content-center">
                <h3 className="title text-center">
                    {t("calculatorSteps.azimuthAngle.title")}
                </h3>
            </Row>
            <Row className='basic-calculator-steps-body'>
                <Col className='roof-orientation-input' xs="12" sm="12" md="5" lg="5" xl="5">
                    <img
                        alt="roof from above.png"
                        src={require("assets/img/roof from above.png")}
                        style={{ transform: "rotate(" + angle + "deg)" }}
                    ></img>
                    <div className="rooftop-slope-slide" style={{ width: "100%" }}>
                        <Slider
                            aria-label="Restricted values"
                            defaultValue={angle}
                            valueLabelFormat={valuetext}
                            getAriaValueText={valuetext}
                            step={5}
                            valueLabelDisplay="auto"
                            marks={marks}
                            min={-180}
                            max={180}
                            onChange={handleChange}
                        />
                    </div>
                </Col>
                <Col className='roof-orientation-description' xs="12" sm="12" md="7" lg="7" xl="7" style={{ flexWrap: "wrap", flexDirection: "row-reverse" }}>
                    <h5>
                        {t("calculatorSteps.azimuthAngle.description")}
                    </h5>
                    <TextField
                        label={t("calculatorSteps.azimuthAngle.inputFieldText")}
                        key={angle}
                        defaultValue={angle}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Col>
            </Row>
        </>
    );
}

export default RoofOrientation;