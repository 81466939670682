import React from 'react';

// core components
import SiteNavbarColored from "components/Navbars/SiteNavbarColored.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import axios from 'axios';
import { Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import { Container } from 'reactstrap';
import SiteNavbar from 'components/Navbars/SiteNavbar';
import ContactUsPageHeader from 'components/ContactUs/ContactUsHeader';
import EmailService from 'services/EmailService';
import '.././assets/css/contact-us.css';

import { useTranslation } from 'react-i18next';

function ContactUsPage(props) {

    const { t, i18n } = useTranslation();

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [message, setMessage] = React.useState("");

    const handleSubmit = (e) => {
        e.preventDefault();

        if (firstName != "" && lastName != "" && email != "" && phoneNumber != "" && message != "") {
            let data = {
                firstName,
                lastName,
                email,
                phoneNumber,
                message,
            };

            // EmailService.contactFormEmail(data).then((res) => {
            //     alert("Hvala Vam.")
            // });

            axios.post(process.env.REACT_APP_CONTACT_FORM_EMAIL_URL,
                {
                    "firstName": data.firstName,
                    "lastName": data.lastName,
                    "email": data.email,
                    "phoneNumber": data.phoneNumber,
                    "message": data.message
                }
            ).then((res) => {
                alert("Hvala Vam na unosu.")
                window.location.reload(false);
            });
        }

    };

    React.useEffect(() => {
        document.body.classList.add("basic-calculator");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("basic-calculator");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    return (
        <>
            <SiteNavbar />
            <div className="wrapper">
                <ContactUsPageHeader />
                <div className='section' style={{ padding: "0" }}>
                    <Container>
                        <div id="contact-container" className='container'>
                            <div className='contact-form-header'>
                                <h2>
                                    {t('contactUsPage.title')}
                                </h2>
                                <p>
                                    {t([`contactUsPage.description1`])}
                                    <a href="/faq">{t(`contactUsPage.description1Link`)}</a>
                                </p>
                                <p>
                                    {t(`contactUsPage.description2`)}
                                    <a href="mailto:office@solarlink.app">office@solarlink.app</a>.
                                </p>
                                <p>
                                    {t(`contactUsPage.description3`)}
                                </p>
                            </div>
                            <div className='contact-form'>
                                <form>
                                    <Grid container spacing={1}>
                                        <Grid xs={12} sm={6} item>
                                            <TextField
                                                value={firstName}
                                                onChange={(event) => {
                                                    setFirstName(event.target.value);
                                                }}
                                                label={t("stringsTranslations.firstName")}
                                                variant="outlined"
                                                fullWidth required />
                                        </Grid>
                                        <Grid xs={12} sm={6} item>
                                            <TextField
                                                value={lastName}
                                                onChange={(event) => {
                                                    setLastName(event.target.value);
                                                }}
                                                label={t("stringsTranslations.lastName")}
                                                variant="outlined"
                                                fullWidth required />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                value={email}
                                                onChange={(event) => {
                                                    setEmail(event.target.value);
                                                }}
                                                type="email"
                                                label={t("stringsTranslations.email")}
                                                variant="outlined"
                                                fullWidth required />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                value={phoneNumber}
                                                onChange={(event) => {
                                                    setPhoneNumber(event.target.value);
                                                }}
                                                type="number"
                                                label={t("stringsTranslations.phoneNumber")}
                                                variant="outlined"
                                                fullWidth />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                value={message}
                                                onChange={(event) => {
                                                    setMessage(event.target.value);
                                                }}
                                                label={t(`stringsTranslations.message`)}
                                                multiline
                                                rows={4}
                                                variant="outlined"
                                                fullWidth required />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                onClick={(e) => handleSubmit(e)}
                                            >
                                                {t(`stringsTranslations.sendMessage`)}
                                            </Button>
                                        </Grid>

                                    </Grid>
                                </form>
                            </div>
                        </div>
                    </Container>
                </div>
                <DefaultFooter />
            </div>
        </>
    );

}

export default ContactUsPage;