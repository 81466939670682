import axios from "axios";
import { t } from "i18next";
import { Trans } from "react-i18next";

class CalculatorService {
  getConclusionText(conclusion, recievedData) {
    switch (conclusion) {
      case "Enough space for recommended power":
        return (
          <>
            <p>
             {t("calculatorSteps.results.field1", {
                 recommendedPower: Math.round(recievedData.results.recommended_power * 100) / 100
             })}
            </p>
            <p>
              {t("calculatorSteps.results.field2", {
                 priceMin: Math.round(recievedData.results.min_price),
                 priceMax: Math.round(recievedData.results.max_price),
                 expectedYears: Math.round(recievedData.results.roi * 10) / 10
             })}
            </p>
          </>
        );

      case "Not enough space for recommended power":
        return (
          <>
            <p>
            {t("calculatorSteps.results.field3", {
                 recommendedPower: Math.round(recievedData.results.recommended_power * 100) / 100,
                 maxPower: Math.round(recievedData.results.power * 100) / 100,
                 yearlyProduction: Math.round(recievedData.results.production)
             })}
            </p>
            <p>
            {t("calculatorSteps.results.field2", {
                 priceMin: Math.round(recievedData.results.min_price),
                 priceMax: Math.round(recievedData.results.max_price),
                 expectedYears: Math.round(recievedData.results.roi * 10) / 10
             })}
            </p>
          </>
        );

      default:
        return <>ERROR</>;
    }
  }

  getConclusionTextInudstryCalculator(conclusion, recievedData) {
    switch (conclusion) {
      case "Enough space for recommended power":
        return (
          <>
            <p>
            {t("calculatorSteps.results.field4", {
                 recommendedPower: Math.round(recievedData.results.preporucena_snaga * 100) / 100
             })}rezultat
              <br/>
              {t("calculatorSteps.results.field5", {
                 rezultat: Math.round(recievedData.results.godisnja_proizvodnja[0])
             })}
              <br />
            </p>
            <p>
            {t("calculatorSteps.results.field2", {
                 priceMin: Math.round(recievedData.results.min_cena_investicije),
                 priceMax: Math.round(recievedData.results.max_cena_investicije),
                 expectedYears: Math.round(recievedData.results.roi * 10) / 10
             })}
            </p>
          </>
        );
      // return <>
      // <p>
      //     Preporučena snaga elektrane na osnovu potrošnje je <b>{Math.round(recievedData.results.preporucena_snaga * 100) / 100}kWp</b>.<br />
      //     Godišnja proizvodnja električne energije za ovakav sistem je <b>{Math.round(recievedData.results.godisnja_proizvodnja[0])}kWh</b>.<br />
      // </p>
      // <p>
      //     Cena ovakve investicije je u opsegu od <b>{Math.round(recievedData.results.min_cena_investicije)}</b> do <b>{Math.round(recievedData.results.max_cena_investicije)} EUR</b>, dok se povraćaj investicije, sa trenutnim cenama električne energije, očekuje posle <b>{Math.round(recievedData.results.roi * 10) / 10} godina</b>.
      // </p>
      // </>

      case "Not enough space for recommended power":
        return (
          <>
            <p>
            {t("calculatorSteps.results.field6", {
                 recommendedPower: Math.round(recievedData.results.preporucena_snaga * 100) / 100, 
                 power: Math.round(recievedData.results.snaga * 100) / 100
            })}
              <br />
              {t("calculatorSteps.results.field5", {
                 rezultat: Math.round(recievedData.results.godisnja_proizvodnja[0])
             })}
              .<br />
            </p>
            <p>
            {t("calculatorSteps.results.field2", {
                 priceMin: Math.round(recievedData.results.min_cena_investicije),
                 priceMax: Math.round(recievedData.results.max_cena_investicije),
                 expectedYears: Math.round(recievedData.results.roi * 10) / 10
             })}
            </p>
          </>
        );
      // return <>
      //     <p>
      //         Preporučena snaga elektrane na osnovu potrošnje je <b>{Math.round(recievedData.results.preporucena_snaga * 100) / 100}kWp</b>, ali je maksimalna snaga koja se može instalirati na Vašem krovu <b>{Math.round(recievedData.results.snaga * 100) / 100}kWp</b>.<br />
      //         Godišnja proizvodnja električne energije za ovakav sistem je <b>{Math.round(recievedData.results.godisnja_proizvodnja[0])}kWh</b>.<br />
      //     </p>
      //     <p>
      //         Cena ovakve investicije je u opsegu između <b>{Math.round(recievedData.results.min_cena_investicije)}</b> do <b>{Math.round(recievedData.results.max_cena_investicije)} EUR</b>, dok se povraćaj investicije, sa trenutnim cenama električne energije, očekuje posle <b>{Math.round(recievedData.results.roi * 10) / 10} godina</b>.
      //     </p>
      // </>

      default:
        return <>ERROR</>;
    }
  }

  industryCalculation(data) {
    return axios.post(process.env.REACT_APP_COMMUNITY_INDUSTRY_CALCULATOR_API, {
      lat: data.latitude,
      lon: data.longitude,
      roof_area: data.surface,
      aspect: data.aspect,
      angle: data.angle,
      annualVacationData: data.annualVacationData,
      maximumAuthorizedPower: data.maximumAuthorizedPower,
      monthlyConsumption: data.monthlyConsumption,
      workingHours: data.workingHours,
    });
  }

  householdsCalculation(data) {
    return axios.post(
      process.env.REACT_APP_COMMUNITY_HOUSEHOLDS_CALCULATOR_API,
      {
        lat: data.lat,
        lon: data.lon,
        roof_area: data.roof_area,
        aspect: data.aspect,
        angle: data.angle,
        consumption: data.consumption,
      }
    );
  }
}

export default new CalculatorService();
