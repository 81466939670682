import * as React from 'react';
import TextField from '@mui/material/TextField';

import {
    Row,
    Col,
} from "reactstrap";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Button, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { set } from 'react-ga';
import dayjs from 'dayjs';
import MainService from 'services/MainService';

import { t } from 'i18next';

const locale = 'en-gb';

function getTimeInFormat(date) {
    const hours = String(date.get('hour')).padStart(2, '0');
    const minutes = String(date.get('minute')).padStart(2, '0');
    return hours + minutes;
}

function WorkingHoursInputForm(props) {

    const [displayAllDays, setDisplayAllDays] = React.useState(false);
    const [timeFrom, setTimeFrom] = React.useState(null);
    const [timeTo, setTimeTo] = React.useState(null);

    const handleDisplayAllDays = () => {
        setDisplayAllDays(!displayAllDays);
    }

    const handleWorkingHoursChange = (newValue, day, fromOrTo) => {

        let formatedTimeValue = null;

        let from = fromOrTo == "from" ? newValue : timeFrom;
        let to = fromOrTo == "to" ? newValue : timeTo;

        if (fromOrTo == "from") {
            if (to != null) {
                formatedTimeValue = getTimeInFormat(newValue) + "-" + getTimeInFormat(timeTo);
            }
            setTimeFrom(newValue);
        }

        if (fromOrTo == "to") {
            if (from != null) {
                formatedTimeValue = getTimeInFormat(timeFrom) + "-" + getTimeInFormat(newValue);
            }
            setTimeTo(newValue);
        }

        if (formatedTimeValue == null) return;

        if (day == "workingWeek") {

            props.setWorkingHours({
                ...props.workingHours,
                ["monday"]: formatedTimeValue,
                ["tuesday"]: formatedTimeValue,
                ["wednesday"]: formatedTimeValue,
                ["thursday"]: formatedTimeValue,
                ["friday"]: formatedTimeValue
            });

        } else {
            props.setWorkingHours({
                ...props.workingHours,
                [day]: formatedTimeValue
            });
        }
    }

    return (
        <>
            {
                displayAllDays
                    ? <>
                        <WorkingHoursInputElement day={"monday"} workingHours={props.workingHours.monday} handleWorkingHoursChange={handleWorkingHoursChange} />
                        <WorkingHoursInputElement day={"tuesday"} workingHours={props.workingHours.tuesday} handleWorkingHoursChange={handleWorkingHoursChange} />
                        <WorkingHoursInputElement day={"wednesday"} workingHours={props.workingHours.wednesday} handleWorkingHoursChange={handleWorkingHoursChange} />
                        <WorkingHoursInputElement day={"thursday"} workingHours={props.workingHours.thursday} handleWorkingHoursChange={handleWorkingHoursChange} />
                        <WorkingHoursInputElement day={"friday"} workingHours={props.workingHours.friday} handleWorkingHoursChange={handleWorkingHoursChange} />
                        <Button onClick={() => handleDisplayAllDays()} style={{ justifyContent: "flex-end" }}>
                            {t('calculatorSteps.workingHours.collapse')}
                        </Button>
                    </>
                    : <>
                        <WorkingHoursInputElement dayName={"Ponedeljak - Petak"} day={"workingWeek"} workingHours={props.workingHours.friday} handleWorkingHoursChange={handleWorkingHoursChange} />
                        <Button onClick={() => handleDisplayAllDays()} style={{ justifyContent: "flex-end" }}>
                            {t('calculatorSteps.workingHours.expand')}
                        </Button>
                    </>
            }
            <WorkingHoursInputElement day={"saturday"} workingHours={props.workingHours.saturday} handleWorkingHoursChange={handleWorkingHoursChange} />
            <WorkingHoursInputElement day={"sunday"} workingHours={props.workingHours.sunday} handleWorkingHoursChange={handleWorkingHoursChange} />
        </>
    );

}

function WorkingHoursInputElement(props) {

    const [dayOff, setDayOff] = React.useState(() => {
        if (props.day == "saturday" || props.day == "sunday") {
            return true;
        }
        else {
            return false;
        }
    });

    return (
        <div className='working-hours-input-day'>
            <div className='working-hours-input-day-name'>
                {
                    props.day != "workingWeek" &&
                    <Checkbox checked={!dayOff} style={{ padding: "0" }} onClick={() => setDayOff(!dayOff)} />
                }
                <h5 style={{ marginLeft: "10px" }}>
                    {t("daysOfTheWeek." + props.day)}
                </h5>
            </div>
            {
                !dayOff &&
                // <CustomTimePicker
                //     label="My Time Picker"
                //     value={"8:00"}
                //     setSelectedTime={(time) => {
                //         const minTime = moment("4:59", "HH:mm");
                //         const maxTime = moment("9:01", "HH:mm");
                //         if (minTime.isBefore(time) && maxTime.isAfter(time)) {
                //             this.changeTime(time.format("HH:mm"));
                //             error = false;
                //         } else {
                //             error = "Select between 5:00 and 9:00";
                //         }
                //         this.setState({ error });
                //     }}
                //     errorMessage={error}
                // />
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
                    <TimePicker
                        label="From"
                        value={props.day}
                        ampm={false}
                        minutesStep={30}
                        skipDisabled={true}
                        onChange={(newValue) => props.handleWorkingHoursChange(newValue, props.day, "from")}
                    />
                    <TimePicker
                        label="To"
                        value={props.day}
                        ampm={false}
                        minutesStep={30}
                        skipDisabled={true}
                        onChange={(newValue) => props.handleWorkingHoursChange(newValue, props.day, "to")}
                    />
                </LocalizationProvider>
            }
        </div>
    );

}

// function CustomTimePicker(props) {
//     return (
//         <MuiPickersUtilsProvider utils={MomentUtils}>
//             <div className="timepicker">
//                 <TimePicker
//                     label={props.label ? props.label : "Time Picker"}
//                     value={
//                         props.value
//                             ? moment(props.value, "HH:mm")
//                             : moment("00:00", "HH:mm")
//                     }
//                     ampm={false}
//                     autoOk={true}
//                     minutesStep={1}
//                     onChange={(time) => props.setSelectedTime(time)}
//                     error={props.errorMessage ? true : false}
//                 />
//                 {props.errorMessage && (
//                     <span className="error">{props.errorMessage}</span>
//                 )}
//             </div>
//         </MuiPickersUtilsProvider>
//     );
// }

export default WorkingHoursInputForm;