import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

// Styles
import "./assets/css/solarlink.css";
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss?v=1.5.0";
import "./assets/demo/demo.css?v=1.5.0";
// import "./assets/demo/nucleo-icons-page-styles.css?v=1.5.0";

//Pages
import App from 'App';

// Google Analytics
import ReactGA from 'react-ga';
import { Analytics } from '@mui/icons-material';
const TRACKING_ID = "G-G255YD2VCK";

const root = ReactDOM.createRoot(document.getElementById('root'));

//Initialize GA4
ReactGA.initialize(TRACKING_ID);

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);