import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components
import SiteNavbar from "components/Navbars/SiteNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import HouseholdsCalculatorHeader from "components/HouseholdsCalculator/HouseholdsCalculatorHeader";
import IndustryCalculatorSteps from "components/IndustryCalculator/IndustryCalculatorSteps";
import Country from "components/CalculatorSteps/Country";

import ".././assets/css/solarlink-style/basic-calculator.css";
import ".././assets/css/solarlink-style/industry-calculator.css";

import { t } from "i18next";
import SoftwareForIndustryHeader from "components/SoftwareForIndustry/SoftwareForIndustryHeader";
import PricingCards from "components/SoftwareForIndustry/PricingCards";
import { Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";

function SoftwareForIndustryPage() {
  const { t, i18n } = useTranslation();

  const [companyName, setCompanyName] = React.useState("");
  const [taxID, setTaxID] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
//  const [message, setMessage] = React.useState("");
  const [subscriptionType, setSubscriptionType] = React.useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      companyName != "" &&
      taxID != "" &&
      country != "" &&
      address != "" &&
      firstName != "" &&
      lastName != "" &&
      email != "" &&
      phoneNumber != "" &&
//      message != "" &&
      subscriptionType != "" 
    ) {
      let data = {
        companyName,
        taxID,
        country,
        address,
        firstName,
        lastName,
        email,
        phoneNumber,
//        message,
        subscriptionType
      };

      // EmailService.contactFormEmail(data).then((res) => {
      //     alert("Hvala Vam.")
      // });

      axios
        .post(process.env.REACT_APP_SOFTWARE_INQUIRY_FORM, {
          company_name: data.companyName,
          tax_id: data.taxID,
          country: data.country,
          address: data.address,
          first_name: data.firstName,
          last_name: data.lastName,
          email_address: data.email,
          phone_number: data.phoneNumber,
//          message: data.message,
          subscription_type: data.subscriptionType
        })
        .then((res) => {
//          alert("Hvala Vam na unosu.");
          window.location.reload(false);
        });
    }
  };

  const handleChange = (event) => {
    setSubscriptionType(event.target.value);
  };

  React.useEffect(() => {
    document.body.classList.add("basic-calculator");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("basic-calculator");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <SiteNavbar />
      <div className="wrapper">
        <SoftwareForIndustryHeader />
        <div className="section" style={{ padding: "0", marginTop: "50px" }}>
          <Container style={{ marginBottom: "50px" }}>
            <h2>{t("softwareForDesignCompanies.title")}</h2>

            <p>{t("softwareForDesignCompanies.description1")}</p>
            <p>{t("softwareForDesignCompanies.description2")}</p>

            <p>
              <b>office@solarlink.app</b>
            </p>

            <PricingCards />
            <div id="contact-container2" className="container">
              <div className="contact-form-header">
                <h2>{t("readyToGetStarted.title")}</h2>
                <p>{t([`readyToGetStarted.description1`])}</p>
                <p>{t(`readyToGetStarted.description2`)}</p>
              </div>
              <div className="contact-form">
                <form>
                  <Grid container spacing={1}>
                    <Grid xs={12} sm={6} item>
                      <TextField
                        value={companyName}
                        onChange={(event) => {
                          setCompanyName(event.target.value);
                        }}
                        label={t("stringsTranslations.companyName")}
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <TextField
                        value={taxID}
                        onChange={(event) => {
                          setTaxID(event.target.value);
                        }}
                        label={t("stringsTranslations.taxID")}
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <TextField
                        value={country}
                        onChange={(event) => {
                          setCountry(event.target.value);
                        }}
                        label={t("stringsTranslations.country")}
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <TextField
                        value={address}
                        onChange={(event) => {
                          setAddress(event.target.value);
                        }}
                        label={t("stringsTranslations.address")}
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <TextField
                        value={firstName}
                        onChange={(event) => {
                          setFirstName(event.target.value);
                        }}
                        label={t("stringsTranslations.firstName")}
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <TextField
                        value={lastName}
                        onChange={(event) => {
                          setLastName(event.target.value);
                        }}
                        label={t("stringsTranslations.lastName")}
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <TextField
                        value={email}
                        onChange={(event) => {
                          setEmail(event.target.value);
                        }}
                        type="email"
                        label={t("stringsTranslations.email")}
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <TextField
                        value={phoneNumber}
                        onChange={(event) => {
                          setPhoneNumber(event.target.value);
                        }}
                        label={t(`stringsTranslations.phoneNumber`)}
                        variant="outlined"
                        fullWidth
                        required
                      />
                    </Grid>
                 {/*    <Grid item xs={12}>
                      <TextField
                        value={message}
                        onChange={(event) => {
                          setMessage(event.target.value);
                        }}
                        label={t(`stringsTranslations.message`)}
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid> */}
                    <Grid xs={12} sm={6} item>
                      <InputLabel id="demo-simple-select-label">
                      {t(`stringsTranslations.subscriptionType`)}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={subscriptionType}
                        label="Subscription Type"
                        onChange={handleChange}
                        style={{ minWidth: "150px" }}
                  
                      >
                        <MenuItem value={1}>{t("stringsTranslations.monthly")}</MenuItem>
                        <MenuItem value={2}>{t("stringsTranslations.annually")}</MenuItem>
                      </Select>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                      <Button
                        block
                        className="btn-round btn-faqs"
                        type="submit"
                        variant="contained"
                        color="info"
                        fullWidth
                        onClick={(e) => handleSubmit(e)}
                        style={{ fontSize: "20px", fontWeight: "600" }}
                      >
                        {t(`stringsTranslations.sendMessage`)}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </div>
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default SoftwareForIndustryPage;
