import React from "react";
import ButtonMu from '@mui/material/Button';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col
} from "reactstrap";

function RegisterForm(props) {

    const [elementInFocus, setElementInFocus] = React.useState(false);

    React.useEffect(() => {
        document.body.classList.add("login-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("login-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    return (
        <>
            <div>
                <Container>
                    <Col className="col-ml-12 col-mr-12 col-md-12">
                        <Card className="card-login card-plain">
                            <Form action="" className="form" method="">
                                <CardHeader className="text-center">
                                    <div className="logo-container">
                                        <img
                                            alt="solarlink"
                                            src={require("assets/img/solarlink-full-blue.png")}
                                        ></img>
                                    </div>
                                </CardHeader>
                                <CardBody style={{ paddingBottom: "0" }}>
                                    <InputGroup
                                        className={
                                            "no-border input-lg" +
                                            (elementInFocus === 1 ? " input-group-focus" : "")
                                        }
                                    >
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-envelope"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="First name"
                                            type="text"
                                            onFocus={() => setElementInFocus(1)}
                                            onBlur={() => setElementInFocus(false)}
                                        ></Input>
                                    </InputGroup>
                                    <InputGroup
                                        className={
                                            "no-border input-lg" +
                                            (elementInFocus === 2 ? " input-group-focus" : "")
                                        }
                                    >
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-envelope"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Last name"
                                            type="text"
                                            onFocus={() => setElementInFocus(2)}
                                            onBlur={() => setElementInFocus(false)}
                                        ></Input>
                                    </InputGroup>
                                    <InputGroup
                                        className={
                                            "no-border input-lg" +
                                            (elementInFocus === 3 ? " input-group-focus" : "")
                                        }
                                    >
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-envelope"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Email"
                                            type="text"
                                            onFocus={() => setElementInFocus(3)}
                                            onBlur={() => setElementInFocus(false)}
                                        ></Input>
                                    </InputGroup>
                                    <InputGroup
                                        className={
                                            "no-border input-lg" +
                                            (elementInFocus === 4 ? " input-group-focus" : "")
                                        }
                                    >
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-key"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Password"
                                            type="text"
                                            onFocus={() => setElementInFocus(4)}
                                            onBlur={() => setElementInFocus(false)}
                                        ></Input>
                                    </InputGroup>
                                    <InputGroup
                                        className={
                                            "no-border input-lg" +
                                            (elementInFocus === 5 ? " input-group-focus" : "")
                                        }
                                    >
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-key"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Confirm password"
                                            type="text"
                                            onFocus={() => setElementInFocus(5)}
                                            onBlur={() => setElementInFocus(false)}
                                        ></Input>
                                    </InputGroup>
                                </CardBody>
                                <CardFooter className="text-center" style={{ marginTop: "0" }}>
                                    <h6>
                                        If you continue, you accept Terms of Use and Privacy Policy.
                                    </h6>
                                    <Button
                                        block
                                        className="btn-round"
                                        color="info"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                        size="lg"
                                    >
                                        Create Account
                                    </Button>
                                    <div className="pull-right">
                                        <h6>
                                            <ButtonMu className="link" style={{ color: "white" }} onClick={props.changeForm}>
                                                Already have an account?<br />
                                                Login here!
                                            </ButtonMu>
                                        </h6>
                                    </div>
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>
                </Container>
            </div>
        </>
    );

}

export default RegisterForm;
