/*eslint-disable*/
import React from "react";

import { useTranslation } from "react-i18next";
// reactstrap components
import { Container } from "reactstrap";
// core components

function HomeHeader() {

  let pageHeader = React.createRef();
  const { t } = useTranslation();

  React.useEffect(() => {
    // if (window.innerWidth > 991) {
    //   const updateScroll = () => {
    //     let windowScrollTop = window.pageYOffset / 3;
    //     pageHeader.current.style.transform =
    //       "translate3d(0," + windowScrollTop + "px,0)";
    //   };
    //   window.addEventListener("scroll", updateScroll);
    //   return function cleanup() {
    //     window.removeEventListener("scroll", updateScroll);
    //   };
    // }
  });

  return (
    <>
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/home-header.png") + ")",
            backgroundPosition: "right 20% top"
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="page-header-headings">
            <h1>{t("home.header.title")}</h1>
            <h2>{t("home.header.subtitle")}</h2>
          </div>
        </Container>
      </div>
      <div className="page-header-slogan">
        <h2 className="title" style={{marginBottom: 0}}>
          {t("home.header.sloganSup")}
        </h2>
      </div>
    </>
  );
}

export default HomeHeader;
