import MainService from './MainService';

class PDFService {

    industryCalculatorPrepareData(data, investitor) {
        return {
            investorHeadingField: investitor,
            investorField: investitor,
            dateField: MainService.getTodayDate(),
            addressField: `${MainService.degToDMS(data.inputs.lat)}, ${MainService.degToDMS(data.inputs.lon)}`,
            rooftopSlopeField: data.inputs.angle,
            roofAzimuthField: data.inputs.aspect,
            maxAutorisedField: data.inputs.maximumAuthorizedPower,
            janField: data.inputs.monthlyConsumption.january,
            febField: data.inputs.monthlyConsumption.february,
            martField: data.inputs.monthlyConsumption.march,
            aprField: data.inputs.monthlyConsumption.april,
            majField: data.inputs.monthlyConsumption.may,
            junField: data.inputs.monthlyConsumption.june,
            julField: data.inputs.monthlyConsumption.july,
            avgField: data.inputs.monthlyConsumption.august,
            septField: data.inputs.monthlyConsumption.september,
            oktField: data.inputs.monthlyConsumption.october,
            novField: data.inputs.monthlyConsumption.december,
            decField: data.inputs.monthlyConsumption.january,
            ponedeljakField: MainService.convertTimeFormat(data.inputs.workingHours.monday),
            utorakField: MainService.convertTimeFormat(data.inputs.workingHours.tuesday),
            sredaField: MainService.convertTimeFormat(data.inputs.workingHours.wednesday),
            cetvrtakField: MainService.convertTimeFormat(data.inputs.workingHours.thursday),
            petakField: MainService.convertTimeFormat(data.inputs.workingHours.friday),
            subotaField: MainService.convertTimeFormat(data.inputs.workingHours.saturday),
            nedeljaField: MainService.convertTimeFormat(data.inputs.workingHours.sunday),
            odmorFiled: data.inputs.annualVacationData.reduce((total, item) => total + parseInt(item.days, 10), 0),
            estimatedPowerFNEField: MainService.roundNumber(data.results.preporucena_snaga, 0),
            maxPowerFNEField: MainService.roundNumber(data.inputs.maximumAuthorizedPower, 0),
            estimatedAnnualEnergyProductionField: MainService.roundNumber(data.results.godisnja_proizvodnja[0], 0),
            surfaceField: MainService.roundNumber(data.results.iskoriscena_povrsina_krova, 0),
            energySavingsField: MainService.roundNumber(data.results.godisnja_usteda[0], 0),
            eurSavingField: MainService.roundNumber(data.results.godisnja_usteda_eur, 0),
            percentageSavingField: MainService.roundNumber(data.results.godisnja_usteda_u_procentima, 0),
            totalEurSavingField: MainService.roundNumber(data.results.ukupna_usteda_u_eksploatacionom_periodu_eur, 0),
            percentageSurplusField: MainService.roundNumber(data.results.godisnji_visak_u_procentima, 0),
            co2SavingsField: MainService.roundNumber(data.results.ukupna_usteda_co2_u_eksploatacionom_periodu, 0),
            maintenanceField: MainService.roundNumber(data.results.cena_godisnjeg_odrzavanja, 0),
            estimatedInvestmentValueFromField: MainService.roundNumber(data.results.min_cena_investicije, 0),
            estimatedInvestmentValueToField: MainService.roundNumber(data.results.max_cena_investicije, 0),
            estimatedReturnOnInvestmentField: MainService.roundNumber(data.results.roi, 2),
        }

    }

    industryCalculatorFillTemplate(form, data) {

        Object.keys(data).forEach(key => {
            form.getTextField(key).setText(data[key] + "");
        });

    }

    householdsCalculatorPrepareData(data, investitor) {
        return {
            investorHeadingField: investitor,
            investorField: investitor,
            addressField: `${MainService.degToDMS(data.inputs.lat)}, ${MainService.degToDMS(data.inputs.lon)}`,
            rooftopSlopeField: data.inputs.angle,
            roofAzimuthField: data.inputs.aspect,
            annualEnergyConsumptionField: data.inputs.consumption,
            estimatedPowerFNEField: (Math.round(data.results.power * 100) / 100).toString(),
            estimatedAnnualEnergyProductionField: (Math.round(data.results.production)).toString(),
            surfaceField: Math.ceil(data.results.used_area),
            estimatedInvestmentValueFromField: (Math.round(data.results.min_price)).toString(),
            estimatedInvestmentValueToField: (Math.round(data.results.max_price)).toString(),
            estimatedReturnOnInvestmentField: (Math.round(data.results.roi * 10) / 10).toString(),
            energySavingsField: Math.round(data.results.savings_kwh),
            eurSavingField: Math.round(data.results.savings_eur).toString(),
            totalEurSavingField: Math.round(data.results.savings_eur_exploitation_period).toString(),
            maintenanceField: Math.round(data.results.maintenance_annualy),
            co2SavingsField: Math.round(data.results.savings_co2_exploitation_period),
        }
    }

    householdsCalculatorFillTemplate(form, data) {

        Object.keys(data).forEach(key => {
            form.getTextField(key).setText(data[key] + "");
        });

    }

}

export default new PDFService();