import React from "react";
import { useTranslation } from "react-i18next";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function ProjectStages() {

  const { t } = useTranslation();
  const [iconPills, setIconPills] = React.useState("1");
  const [pills, setPills] = React.useState("1");

  return (
    <>
      <div className="section section-tabs">
        <Container>
          <Row>
            <Col ml="12" mr="12" md="12" xl="12">
              <p className="category">{t("home.projectStages.title")}</p>
              <Card>
                <CardHeader>
                  <Nav className="justify-content-center" role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        className={iconPills === "1" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("1");
                        }}
                      >
                        1. {t("home.projectStages.first.title")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "2" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("2");
                        }}
                      >
                        2. {t("home.projectStages.second.title")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "3" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("3");
                        }}
                      >
                        3. {t("home.projectStages.third.title")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "4" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("4");
                        }}
                      >
                        4. {t("home.projectStages.fourth.title")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "5" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("5");
                        }}
                      >
                        5. {t("home.projectStages.fifth.title")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "6" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("6");
                        }}
                      >
                        6. {t("home.projectStages.sixth.title")}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "7" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("7");
                        }}
                      >
                        7. {t("home.projectStages.seventh.title")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody>
                  <TabContent
                    className="text-center"
                    activeTab={"iconPills" + iconPills}
                  >
                    <TabPane tabId="iconPills1" className="home-pane">
                      <Col className="mb-5 mb-lg-0" lg="5">
                        <h3>{t("home.projectStages.first.subtitle")}</h3>
                        <p>
                          {t("home.projectStages.first.description")}
                        </p>
                      </Col>
                      <Col lg="7">
                        <img
                          alt="estimate your profit"
                          className="solar-tab-images"
                          src={require("assets/img/home tabs/estimate your profit.webp")}
                        ></img>
                      </Col>
                    </TabPane>
                    <TabPane tabId="iconPills2" className="home-pane">
                      <Col className="mb-5 mb-lg-0" lg="5">
                        <h3>{t("home.projectStages.second.subtitle")}</h3>
                        <p>
                         {t("home.projectStages.second.description")}
                        </p>
                      </Col>
                      <Col lg="7">
                        <img
                          alt="seek for offers from contractors"
                          className="solar-tab-images"
                          src={require("assets/img/home tabs/seek for offers from contractors.webp")}
                        ></img>
                      </Col>
                    </TabPane>
                    <TabPane tabId="iconPills3" className="home-pane">
                      <Col className="mb-5 mb-lg-0" lg="5">
                        <h3>{t("home.projectStages.third.subtitle")}</h3>
                        <p>
                          {t("home.projectStages.third.description")}                        </p>                     
                      </Col>
                      <Col lg="7">
                        <img
                          alt="choose your contractor"
                          className="solar-tab-images"
                          src={require("assets/img/home tabs/choose your contractor.webp")}
                        ></img>
                      </Col>
                    </TabPane>
                    <TabPane tabId="iconPills4" className="home-pane">
                      <Col className="mb-5 mb-lg-0" lg="5">
                        <h3>{t("home.projectStages.fourth.subtitle")}</h3>
                        <p>
                         {t("home.projectStages.fourth.description")}                        </p>                     
                      </Col>
                      <Col lg="7">
                        <img
                          alt="seek for offers from contractors"
                          className="solar-tab-images"
                          src={require("assets/img/home tabs/acquire project documentation for your solar power plant.webp")}
                        ></img>
                      </Col>
                    </TabPane>
                    <TabPane tabId="iconPills5" className="home-pane">
                      <Col className="mb-5 mb-lg-0" lg="5">
                        <h3>{t("home.projectStages.fifth.subtitle")}</h3>
                        <p>
                         {t("home.projectStages.fifth.description")}
                        </p>
                      </Col>
                      <Col lg="7">
                        <img
                          alt="finish installation of the system on your roof"
                          className="solar-tab-images"
                          src={require("assets/img/home tabs/finish installation of the system on your roof.webp")}
                        ></img>
                      </Col>
                    </TabPane>
                    <TabPane tabId="iconPills6" className="home-pane">
                      <Col className="mb-5 mb-lg-0" lg="5">
                        <h3>{t("home.projectStages.sixth.subtitle")}</h3>
                        <p>
                          {t("home.projectStages.sixth.description")}                        </p>
                      </Col>
                      <Col lg="7">
                        <img
                          alt="finishing the project"
                          className="solar-tab-images"

                          src={require("assets/img/home tabs/finishing the project.png")}
                        ></img>
                      </Col>
                    </TabPane>
                    <TabPane tabId="iconPills7" className="home-pane">
                      <Col className="mb-5 mb-lg-0" lg="5">
                        <h3>{t("home.projectStages.seventh.subtitle")}</h3>
                        <p>
                         {t("home.projectStages.seventh.description")}                        </p>
                      </Col>
                      <Col lg="7">
                        <img
                          alt="enjoy benefits from your investment"
                          className="solar-tab-images"
                          src={require("assets/img/home tabs/enjoy benefits.png")}
                        ></img>
                      </Col>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ProjectStages;
