import React, { Component, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useCurrentPng } from 'recharts-to-png';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * iz formata {"january": 12 ... }
 * u format {month: "january", E_m: 12} 
 */
function prepravka(data){
    return [
        {
            "month": "January",
            "consumption": data["january"],
        },
        {
            "month": "February",
            "consumption": data["february"],
        },
        {
            "month": "March",
            "consumption": data["march"],
        },
        {
            "month": "April",
            "consumption": data["april"],
        },
        {
            "month": "May",
            "consumption": data["may"],
        },
        {
            "month": "June",
            "consumption": data["june"],
        },
        {
            "month": "July",
            "consumption": data["july"],
        },
        {
            "month": "August",
            "consumption": data["august"],
        },
        {
            "month": "September",
            "consumption": data["september"],
        },
        {
            "month": "October",
            "consumption": data["october"],
        },
        {
            "month": "November",
            "consumption": data["november"],
        },
        {
            "month": "December",
            "consumption": data["december"],
        }
    ];
}

// Python vraca podatke u formatu:
// {
//     "results": {
//         "months": [
//             {
//                 "month": "Januar",
//                 "E_m": 0.0
//             },
//             ...
//         ]
//     }
// }
// Zato prevodim mesece u potrebni jezik


function ProductionConsumptionGraph(props) {

    const { t } = useTranslation();

    // useCurrentPng usage (isLoading is optional)
    const [getPng, { ref }] = useCurrentPng();

    useEffect(() => {
        setGraphDataToParent();
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            if (setGraphDataToParent()) {
                clearInterval(timer);
            }
        }, 1000);
    }, []);

    const prepareData = (production, consumptionData) => {

        if(production == undefined || consumptionData == undefined) return [];
    
        let consumption = prepravka(consumptionData);
    
        let out = [
            {
                "month": t("months.january"),
                "production": production.find(item => item.month === 'January').E_m,
                "consumption": consumption.find(item => item.month === 'January').consumption,
            },
            {
                "month": t("months.february"),
                "production": production.find(item => item.month === 'February').E_m,
                "consumption": consumption.find(item => item.month === 'February').consumption,
            },
            {
                "month": t("months.march"),
                "production": production.find(item => item.month === 'March').E_m,
                "consumption": consumption.find(item => item.month === 'March').consumption,
            },
            {
                "month": t("months.april"),
                "production": production.find(item => item.month === 'April').E_m,
                "consumption": consumption.find(item => item.month === 'April').consumption,
            },
            {
                "month": t("months.may"),
                "production": production.find(item => item.month === 'May').E_m,
                "consumption": consumption.find(item => item.month === 'May').consumption,
            },
            {
                "month": t("months.june"),
                "production": production.find(item => item.month === 'June').E_m,
                "consumption": consumption.find(item => item.month === 'June').consumption,
            },
            {
                "month": t("months.july"),
                "production": production.find(item => item.month === 'July').E_m,
                "consumption": consumption.find(item => item.month === 'July').consumption,
            },
            {
                "month": t("months.august"),
                "production": production.find(item => item.month === 'August').E_m,
                "consumption": consumption.find(item => item.month === 'August').consumption,
            },
            {
                "month": t("months.september"),
                "production": production.find(item => item.month === 'September').E_m,
                "consumption": consumption.find(item => item.month === 'September').consumption,
            },
            {
                "month": t("months.october"),
                "production": production.find(item => item.month === 'October').E_m,
                "consumption": consumption.find(item => item.month === 'October').consumption,
            },
            {
                "month": t("months.november"),
                "production": production.find(item => item.month === 'November').E_m,
                "consumption": consumption.find(item => item.month === 'November').consumption,
            },
            {
                "month": t("months.december"),
                "production": production.find(item => item.month === 'December').E_m,
                "consumption": consumption.find(item => item.month === 'December').consumption,
            }
        ];
    
        return out;
    }

    
const CustomTooltip = ({ active, payload, label }) => {

    const { t } = useTranslation();
    const production = t("stringsTranslations.production");
    const consumption = t("stringsTranslations.consumption");

    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label" style={{color:"black"}}>{label}</p>
                <p className="desc" style={{color:"#225876"}}>{`${consumption}: ${Math.round(payload[1].value)}kWh`}</p>
                <p className="desc">{`${production}: ${Math.round(payload[0].value)}kWh`}</p>
            </div>
        );
    }

    return null;
};


    const setGraphDataToParent = useCallback(async () => {

        const png = await getPng();
        props.setPNG(png);

        return png != undefined;

    }, [getPng]);

    return (
        <>
            {props.production != null && props.consumption != null 
                ? <ResponsiveContainer width="110%" height={300}>
                    <LineChart
                        ref={ref}
                        // width={960}
                        // height={400}
                        data={prepareData(props.production, props.consumption)}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                    >
                        <CartesianGrid horizontal={true} vertical={false} strokeDasharray="1" />
                        <XAxis dataKey="month" angle={0} dx={0} />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="production"
                            stroke="#fcc11b"
                            strokeWidth="5px"
                            activeDot={{ r: 8, fill: "#fcc11b" }}
                            name={t("stringsTranslations.production")}
                        />
                        <Line
                            type="monotone"
                            dataKey="consumption"
                            stroke="#225876"
                            strokeWidth="5px"
                            activeDot={{ r: 8, fill: "#225876" }}
                            name={t("stringsTranslations.consumption")}
                        />
                    </LineChart>
                </ResponsiveContainer>
                : <>
                nema
                </>
            }
        </>
    );
}

export default ProductionConsumptionGraph;