import React from "react";
import faqBanner from "../../assets/img/faq-banner.png"

function FAQHeader() {
    let pageHeader = React.createRef();

    React.useEffect(() => {
        if (window.innerWidth > 991) {

            const updateScroll = () => {
                let windowScrollTop = window.pageYOffset / 3;
                pageHeader.current.style.transform =
                    "translate3d(0," + windowScrollTop + "px,0)";
            };

            window.addEventListener("scroll", updateScroll);
            return function cleanup() {
                window.removeEventListener("scroll", updateScroll);
            };

        }
    });

    return (
        <>
            <div
                className="page-header page-header-small"
                style={{minHeight: "38vh"}}
            >
                <div
                    className="page-header-image"
                    ref={pageHeader}
                    style={{
                        // backgroundImage: `url(https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png)`,
                        backgroundImage: `url(${faqBanner})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                    }}
                ></div>
            </div>
        </>
    );

}

export default FAQHeader;
