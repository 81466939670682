import React from "react";
import { useTranslation } from "react-i18next";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

import "../../assets/css/webnet-styles/get-in-touche.css";

// core components

function GetInTouch() {

  const { t } = useTranslation();

  return (
    <>
      <div className="section">
        <Container className="text-center">
          <section id="get-in-touch">
            <div className="container-get-in-touch">
              <div className="back-card">
              </div>
              <div className="contact-us-gray-card">
                <a href="/contact">
                  <div className="contact-us-gray-card-sub">
                    <div className="contact-us-gray-card-circle">
                    </div>
                    <h2>
                      {t("getInTouch.contactUs.title")}
                    </h2>
                    <p>
                      {t("getInTouch.contactUs.description")}
                    </p>
                  </div>
                </a>
              </div>
              <div className="faq-us-dark-card">
                <a href="/faq">
                  <div className="faq-us-dark-card-sub">
                    <h2>
                      {t("getInTouch.faqs.title")}
                    </h2>
                    <h3 >
                      {t("getInTouch.faqs.subtitle")}
                    </h3>
                    <p>
                      {t("getInTouch.faqs.description")}
                    </p>
                  </div>
                </a>
              </div>
              <div className="get-in-touch-cicrlce">
              </div>
            </div>
          </section>
        </Container>
      </div>
    </>
  );
}

export default GetInTouch;
