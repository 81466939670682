import React from "react";

import SiteNavbar from "components/Navbars/SiteNavbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
import RegisterForm from "components/RegisterForm";
import LoginForm from "components/LoginForm";

function LoginPage() {

  const [form, setForm] = React.useState("login");

  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  function changeForm(e) {
    e.preventDefault();
    form === "login" ? setForm("registration") : setForm("login");
  }

  return (
    <>
      <SiteNavbar />
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/log-in-beckground.webp") + ")"
          }}
        ></div>
        <div className="content">

        { form === "login" 
          ? <LoginForm changeForm={changeForm} /> 
          : <RegisterForm changeForm={changeForm} />
        }

        </div>
        <TransparentFooter />
      </div>
    </>
  );
}

export default LoginPage;
