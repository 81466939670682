import React from "react";
import { useTranslation } from "react-i18next";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";

// core components

function Calculators() {

  const { t } = useTranslation();

  return (
    <>
      <div className="section">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col lg="6" md="6">
              <div className="calculator-card">
                <h2 className="title">{t("home.calculators.households.title")}</h2>
                <h5 className="description">
                  {t("home.calculators.households.description")}
                </h5>
                <img
                  alt="finish installation of the system on your roof"
                  className="solar-tab-images"
                  src={require("assets/img/Kalkulator za domacinstva.png")}
                ></img>
                <Button
                  block
                  className="btn-round btn-faqs"
                  color="info"
                  href="/households-calculator"
                  // onClick={(e) => e.preventDefault()}
                  size="lg"
                  style={{ fontSize: "20px", fontWeight: "600" }}
                >
                  {t("home.calculators.households.button")}
                </Button>
              </div>
            </Col>
            <Col lg="6" md="6">
              <div className="calculator-card">
                <h2 className="title">{t("home.calculators.industry.title")}</h2>
                <h5 className="description">
                  {t("home.calculators.industry.description")}
                </h5>
                <img
                  alt="finish installation of the system on your roof"
                  className="solar-tab-images"
                  src={require("assets/img/Kalkulator za privredu.png")}
                ></img>
                <Button
                  block
                  className="btn-round btn-contact"
                  color="info"
                  href="/industry-calculator"
                  // onClick={(e) => e.preventDefault()}
                  size="lg"
                  style={{ fontSize: "20px", fontWeight: "600" }}
                >
                  {t("home.calculators.industry.button")}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Calculators;
