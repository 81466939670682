import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  BrowserRouter,
  Routes,
  Route,
  HashRouter
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';

import HomePage from './views/HomePage';
import LoginPage from 'views/LoginPage';
import HouseholdsCalculatorPage from 'views/HouseholdsCalculatorPage';
import i18n from 'i18next';

// import english from './assets/languages/english.json';
// import serbian from './assets/languages/serbian.json';
import english from './assets/i18n/english.json';
import serbian from './assets/i18n/serbian.json';
import { initReactI18next } from "react-i18next";
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';

import FAQ from 'views/FAQ';
import ContactUsPage from 'views/ContactUsPage';
import IndustryCalculatorPage from 'views/IndustryCalculatorPage';
import EnterpriseIndustryCalculatorPage from 'views/enterpriseSoftware/EnterpriseIndustryCalculatorPage';
import EnterpriseCalculatorsPage from 'views/enterpriseSoftware/EnterpriseCalculatorsPage';
import SoftwareForIndustryPage from 'views/SoftwareForIndustryPage';


function App() {

  // useEffect(() => {
  //   ReactGA.initialize(TRACKING_ID);
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  i18n
    .use(initReactI18next)
    .use(I18nextBrowserLanguageDetector)
    .init({
      resources: {
        srb: { translation: serbian },
        en: { translation: english },
      },
      lng: i18n.language,
      fallbackLng: i18n.language,
      interpolation: { escapeValue: false },
      detection: {
        order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
        caches: ['cookie']
      }
    });

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<HomePage />} />

          <Route path="/home" element={<HomePage />} />

          <Route path="/login" element={<LoginPage />} />


          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<ContactUsPage />} />

          <Route path="/households-calculator" element={<HouseholdsCalculatorPage />} />
          <Route path="/industry-calculator" element={<IndustryCalculatorPage />} />
          <Route path="/software" element={<SoftwareForIndustryPage />} />

          <Route path="/enterprise" element={<EnterpriseCalculatorsPage />} />
          <Route path="/enterprise/households-calculator" element={<EnterpriseIndustryCalculatorPage />} />
          <Route path="/enterprise/industry-calculator" element={<EnterpriseIndustryCalculatorPage />} />

          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>404 There's nothing here!</p>
              </main>
            }
          />

        </Routes>
      </BrowserRouter>
    </>
    // <Routes>
    //   <Route path="/" element={<Home />} />
    //   <Route path="invoices" element={<Invoices />}>
    //     <Route path=":invoiceId" element={<Invoice />} />
    //     <Route path="sent" element={<SentInvoices />} />
    //   </Route>
    // </Routes>
  );
}

export default App;
