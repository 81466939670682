import React, { useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import {
    Row,
    Col,
    Button
} from "reactstrap";
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

function RooftopSlope(props) {

    const { t } = useTranslation();

    const [slope, setSlope] = useState(10);

    const marks = [
        {
            value: 0,
            label: '0°',
        },
        {
            value: 15,
            label: '15°',
        },
        {
            value: 30,
            label: '30°',
        },
        {
            value: 45,
            label: '45°',
        },
    ];


    useEffect(() => {
        props.setAngle(slope);
    });

    function valuetext(value) {
        return `${value}°`;
    }

    // function valueLabelFormat(value) {
    //     return marks.findIndex((mark) => mark.value === value) + 1;
    // }

    function handleChange(e) {
        setSlope(e.target.value);
    }

    return (
        <>
            <Row className="justify-content-center">
                <h3 className="title text-center">
                    {t("calculatorSteps.tiltAngle.title")}
                </h3>
            </Row>
            <Row className='basic-calculator-steps-body'>
                <Col className='rooftop-slope-input' xs="12" sm="12" md="5" lg="5" xl="5">
                    <img
                        alt="roof from above.png"
                        src={require("assets/img/basic calculator/roof/roof-slope-" + slope + "-deg.png")}
                    ></img>
                    <div className="rooftop-slope-slide" style={{ width: "100%" }}>
                        <Slider
                            aria-label="Restricted values"
                            defaultValue={slope}
                            valueLabelFormat={valuetext}
                            getAriaValueText={valuetext}
                            step={5}
                            valueLabelDisplay="auto"
                            marks={marks}
                            min={0}
                            max={45}
                            onChange={handleChange}
                        />
                    </div>
                </Col>
                <Col xs="12" sm="12" md="7" lg="7" xl="7" style={{ flexWrap: "wrap", flexDirection: "row-reverse" }}>
                    <h5>
                        {t("calculatorSteps.tiltAngle.description")}
                    </h5>
                    <TextField
                        label={t("calculatorSteps.tiltAngle.inputFieldText")}
                        key={slope}
                        defaultValue={slope}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </Col>
            </Row>

        </>
    );
}

export default RooftopSlope;