import React, { useEffect, useState } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
    Row,
    Col,
    Button
} from "reactstrap";
import OutlinedInput from '@mui/material/OutlinedInput';
import backgroundImage from '../../../assets/img/basic calculator/results-modal.png'
import InputLabel from '@mui/material/InputLabel';
import { FormControl, FormHelperText, Input, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EmailService from 'services/EmailService';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    maxHeight: "80vh",
    overflow: "auto",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "25px",
    p: 4,
};

function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

function ResultsModal(props) {

    const { t } = useTranslation();

    let emailField = React.createRef(),
        investitorField = React.createRef();

    const [emailFieldError, setEmailFieldError] = React.useState(false);
    const [emailFieldErrorMessage, setEmailFieldErrorMessage] = React.useState("");
    const [investitorFieldError, setInvestitorFieldError] = React.useState(false);
    const [investitorFieldErrorMessage, setInvestitorFieldErrorMessage] = React.useState("");

    const [open, setOpen] = React.useState(false);


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
    });

    function btnClick() {
        let valid = true;

        if (emailField.current.value == null || emailField.current.value == "") {
            setEmailFieldError(true);
            setEmailFieldErrorMessage("Ovo polje je obavezno");
            valid = false;
        }
        else if (!validateEmail(emailField.current.value)) {
            setEmailFieldError(true);
            setEmailFieldErrorMessage("Unos mora biti validna email adresa");
            valid = false;
        }
        else {
            setEmailFieldError(false);
            setEmailFieldErrorMessage("");
        }

        if (investitorField.current.value == null || investitorField.current.value == "") {
            setInvestitorFieldError(true);
            setInvestitorFieldErrorMessage("Ovo polje je obavezno");
            valid = false;
        }
        else {
            setInvestitorFieldError(false);
            setInvestitorFieldErrorMessage("");
        }

        if (valid) {
            EmailService.subscribe({ email: emailField.current.value, type: "households-calculator" })
                .then(response => {
                    props.generatePDF(investitorField.current.value);
                })
                .catch(error => {
                    console.log(error);
                });
        }

    };

    return (
        <div>
            <div className="float-right" style={{ marginBottom: "50px" }}>
                <Button className="btn-round" color="info" type="button" onClick={handleOpen}>
                    {t("resultsModal.openModalText")}
                    {/* <i className="now-ui-icons files_single-copy-04"></i> */}
                </Button>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="transition-modal-title" variant="h6" component="h2">
                            {t("resultsModal.modalTitle")}
                        </Typography>
                        <Typography id="transition-modal-description">
                            <Row className="justify-content-center" style={{ display: "flex", alignItems: "center" }}>
                                <Col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <img src={backgroundImage} style={{ maxWidth: "250px" }} />
                                </Col>
                                <Col xs="12" sm="12" md="8" lg="8" xl="8">
                                    <h4>{t("resultsModal.modalSubtitle")}</h4>
                                    {t("resultsModal.explanation")}
                                    {/* <FormControl style={{ width: "100%" }}>
                                        <InputLabel htmlFor="email-address">Email adresa</InputLabel>
                                        <Input id="email-address" aria-describedby="email-address" required/>
                                        <InputLabel htmlFor="investitor">Ime investitora</InputLabel>
                                        <Input id="investitor" aria-describedby="investitor" required={true}/>
                                        <FormHelperText id="investitor">Nikada nećemo deliti Vaš mejl!</FormHelperText>
                                    </FormControl> */}
                                    <TextField
                                        inputRef={emailField}
                                        required
                                        error={emailFieldError}
                                        id="outlined-required"
                                        label={t("resultsModal.emailInputLabel")}
                                        helperText={emailFieldError ? emailFieldErrorMessage : emailFieldError}
                                        style={{ marginTop: "20px", width: "100%" }}
                                    />
                                    <TextField
                                        inputRef={investitorField}
                                        required
                                        error={investitorFieldError}
                                        id="outlined-required"
                                        label={t("resultsModal.investorInputLabel")}
                                        helperText={investitorFieldError ? investitorFieldErrorMessage : investitorFieldError}
                                        style={{ marginTop: "20px", width: "100%" }}
                                    />
                                    <div className="float-right">
                                        <Button className="btn-round" color="info" type="button" onClick={btnClick}>
                                            {t("resultsModal.confirmButton")}
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </Typography>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}

export default ResultsModal;