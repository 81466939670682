import React from "react";

// reactstrap components
import {
    Container,
} from "reactstrap";

// core components
import SiteNavbar from "components/Navbars/SiteNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import HouseholdsCalculatorHeader from "components/HouseholdsCalculator/HouseholdsCalculatorHeader";
import IndustryCalculatorSteps from "components/IndustryCalculator/IndustryCalculatorSteps";
import Country from "components/CalculatorSteps/Country";

// import ".././assets/css/solarlink-style/basic-calculator.css";
// import ".././assets/css/solarlink-style/industry-calculator.css";
import "../../assets/css/enterpriseSoftware/common.css";

import { useTranslation } from "react-i18next";

import { t } from 'i18next';
import { FormControl, FormHelperText, IconButton, InputAdornment, OutlinedInput, TextField, Tooltip } from "@mui/material";

import iconSet from "../../assets/icon-sets/selection.json";
import IcomoonReact from "icomoon-react";
import WorkingHoursInputForm from "components/CalculatorSteps/InputElements/WorkingHoursInputForm";
import CollectiveAnnualVacationInputForm from "components/CalculatorSteps/InputElements/CollectiveAnnualVacationInputForm";
import RoofsDataInputForm from "components/CalculatorSteps/RoofData/RoofsDataInputForm";

function EnterpriseIndustryCalculatorPage(props) {

    React.useEffect(() => {
        document.body.classList.add("basic-calculator");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("basic-calculator");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    const { t } = useTranslation();

    const [maximumAuthorizedPower, setMaximumAuthorizedPower] = React.useState(null);

    const [monthlyConsumption, setMonthlyConsumption] = React.useState({
        january: null,
        february: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        august: null,
        september: null,
        october: null,
        november: null,
        december: null,
    });

    const [workingHours, setWorkingHours] = React.useState({
        monday: "null",
        tuesday: "null",
        wednesday: "null",
        thursday: "null",
        friday: "null",
        saturday: "null",
        sunday: "null",
    });

    const [annualVacationData, setAnnualVacationData] = React.useState([{ "month": "none", "days": "none" }]);
    const [roofsData, setRoofsData] = React.useState([{ "azimuthAngle": "none", "tiltAngle": "none", "freeRoofArea": "none" }]);


    const handleMonthlyConsumptionChange = (event, month) => {
        if (event.target.value < 0) {
            event.target.value = "";
        }

        setMonthlyConsumption({ ...monthlyConsumption, [month]: event.target.value });
    }

    const handleMaximumAuthorizedPowerChange = (event) => {
        if (event.target.value < 0) {
            event.target.value = "";
        }

        setMaximumAuthorizedPower(event.target.value);
    }

    const handleFormSubmit = (event) => {
        alert("klik");
        console.log({
            // latitude,
            // longitude,
            // surface,
            // aspect,
            // angle,
            monthlyConsumption,
            maximumAuthorizedPower,
            workingHours,
            annualVacationData,
        })
    }

    return (
        <>
            <SiteNavbar />
            <div className="wrapper">
                {/* <HouseholdsCalculatorHeader /> */}
                <div className="section" style={{ padding: "0", marginTop: "200px", marginBottom: "200px" }} >
                    <Container>
                        <h2>Enterprise Industry Calcualtor</h2>

                        <div className="enterprise-input-form">

                            <div className="enterprise-input-form-left">

                                <div className="enterprise-card enterprise-input-field">

                                    <h4>Lokacija objekta</h4>

                                    <hr />

                                    <span>Koordinate objekta</span>
                                    <TextField
                                    // label={t("calculatorSteps.location.inputFieldLabel")}
                                    // key={props.getLocation()}
                                    // defaultValue={props.getLocation()}
                                    />

                                </div>

                                <div className="enterprise-card roofs-input-area">

                                    <h4>Podaci o krovovima</h4>

                                    <hr />

                                    <RoofsDataInputForm roofsData={roofsData} setRoofsData={setRoofsData} />

                                </div>

                                <div className="enterprise-card electricity-bill-input-area">

                                    <h4>Mesečne potrošnje električne energije</h4>

                                    <hr />

                                    <div className="electricity-bill-input-area-grid">

                                        <FormControl>
                                            <OutlinedInput
                                                value={monthlyConsumption.january}
                                                placeholder={t("months.january")}
                                                inputProps={monthlyConsumption.january == null ? {} : { style: { textAlign: 'right' } }}
                                                // InputProps={{ inputProps: { min: 0} }}
                                                onChange={(event) => handleMonthlyConsumptionChange(event, "january")}
                                                endAdornment={<InputAdornment position="end">kWh</InputAdornment>}
                                                type="tel"
                                            />
                                        </FormControl>

                                        <TextField
                                            placeholder={t("months.february")}
                                            type="number"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            onChange={(event) => handleMonthlyConsumptionChange(event, "february")}
                                        />

                                        <TextField
                                            placeholder={t("months.march")}
                                            type="number"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            onChange={(event) => handleMonthlyConsumptionChange(event, "march")}
                                        />

                                        <TextField
                                            placeholder={t("months.april")}
                                            type="number"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            onChange={(event) => handleMonthlyConsumptionChange(event, "april")}
                                        />

                                        <TextField
                                            placeholder={t("months.may")}
                                            type="number"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            onChange={(event) => handleMonthlyConsumptionChange(event, "may")}
                                        />

                                        <TextField
                                            placeholder={t("months.june")}
                                            type="number"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            onChange={(event) => handleMonthlyConsumptionChange(event, "june")}
                                        />

                                        <TextField
                                            placeholder={t("months.july")}
                                            type="number"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            onChange={(event) => handleMonthlyConsumptionChange(event, "july")}
                                        />

                                        <TextField
                                            placeholder={t("months.august")}
                                            type="number"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            onChange={(event) => handleMonthlyConsumptionChange(event, "august")}
                                        />

                                        <TextField
                                            placeholder={t("months.september")}
                                            type="number"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            onChange={(event) => handleMonthlyConsumptionChange(event, "september")}
                                        />

                                        <TextField
                                            placeholder={t("months.october")}
                                            type="number"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            onChange={(event) => handleMonthlyConsumptionChange(event, "october")}
                                        />

                                        <TextField
                                            placeholder={t("months.november")}
                                            type="number"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            onChange={(event) => handleMonthlyConsumptionChange(event, "november")}
                                        />

                                        <TextField
                                            placeholder={t("months.december")}
                                            type="number"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            onChange={(event) => handleMonthlyConsumptionChange(event, "december")}
                                        />

                                    </div>

                                    <TextField
                                        className='maximum-authorized-power'
                                        label={t("calculatorSteps.monthlyElectricityConsumption.inputFieldText")}
                                        type="number"
                                        InputProps={{ inputProps: { min: 0 } }}
                                        onChange={(event) => handleMaximumAuthorizedPowerChange(event)}
                                    />

                                </div>
                            </div>

                            <div className="enterprise-input-form-right">

                                <div className="enterprise-card working-hours-input-area">
                                    <h4>Kolektivni godišnji odmori i popravke</h4>

                                    <hr />

                                    <div className="collective-annual-vacation-input">
                                        <CollectiveAnnualVacationInputForm annualVacationData={annualVacationData} setAnnualVacationData={setAnnualVacationData} />
                                    </div>
                                </div>

                                <div className="enterprise-card working-hours-input-area">
                                    <h4>Radno vreme</h4>

                                    <hr />
                                    <WorkingHoursInputForm setWorkingHours={setWorkingHours} workingHours={workingHours} />
                                </div>

                                <div className="enterprise-input-form-submit-button">
                                    <button onClick={() => handleFormSubmit()}>Pokreni kalkulator</button>
                                </div>

                            </div>


                        </div>

                    </Container>
                </div>
                <DefaultFooter />
            </div>
        </>
    );
}

export default EnterpriseIndustryCalculatorPage;
