import React from "react";

// reactstrap components
import {
    Button,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Row,
    Col,
    Progress
} from "reactstrap";

import AverageBill from "../CalculatorSteps/AverageBill";
import HouseSurface from "../CalculatorSteps/HouseSurface";
import Location from "../CalculatorSteps/Location";
import RoofOrientation from "../CalculatorSteps/RoofOrientation";
import RooftopSlope from "../CalculatorSteps/RooftopSlope";
import { useTranslation } from "react-i18next";
import MainService from "services/MainService.js";
import BillData from "components/CalculatorSteps/BillData";
import WorkingHours from "components/CalculatorSteps/WorkingHours";
import CollectiveAnnualVacation from "components/CalculatorSteps/CollectiveAnnualVacation";
import IndustryCalculatorResults from "./IndustryCalculatorResults";
import Country from "components/CalculatorSteps/Country";


const testDataPrazno = {
    "latitude": null,
    "longitude": null,
    "surface": null,
    "aspect": 0,
    "angle": 10,
    "monthlyConsumption":
    {
        "january": null,
        "february": null,
        "march": null,
        "april": null,
        "may": null,
        "june": null,
        "july": null,
        "august": null,
        "september": null,
        "october": null,
        "november": null,
        "december": null
    },
    "maximumAuthorizedPower": null,
    "workingHours": {
        "monday": null,
        "tuesday": null,
        "wednesday": null,
        "thursday": null,
        "friday": null,
        "saturday": null,
        "sunday": null
    },
    "annualVacationData": [{ "month": "none", "days": "none" }]
};

const testDataPuno2 = {
    "latitude": 44.72977943768759,
    "longitude": 20.032109152278235,
    "surface": "100",
    "aspect": 0,
    "angle": 10,
    "monthlyConsumption":
    {
        "january": "1",
        "february": "2",
        "march": "32",
        "april": "4",
        "may": "46",
        "june": "7",
        "july": "53",
        "august": "5465",
        "september": "75",
        "october": "745",
        "november": "3",
        "december": "6"
    },
    "maximumAuthorizedPower": "676",
    "workingHours": {
        "monday": "0900-1500",
        "tuesday": "0900-1500",
        "wednesday": "0900-1500",
        "thursday": "0900-1500",
        "friday": "0900-1500",
        "saturday": "null",
        "sunday": "null"
    },
    "annualVacationData": [{ "month": "april", "days": "5" }]
};
const testDataPuno = {
    "latitude": 44.72977943768759,
    "longitude": 20.032109152278235,
    "surface": "5000",
    "aspect": 30,
    "angle": 10,
    "monthlyConsumption": {
        "january": "5000",
        "february": "40000",
        "march": "32000",
        "april": "40000",
        "may": "46000",
        "june": "70000",
        "july": "53000",
        "august": "54650",
        "september": "75000",
        "october": "74500",
        "november": "30000",
        "december": "60000"
    },
    "maximumAuthorizedPower": "1000",
    "workingHours": {
        "monday": "0900-1700",
        "tuesday": "0900-1700",
        "wednesday": "0900-1700",
        "thursday": "0900-1700",
        "friday": "0900-1700",
        "saturday": "0900-1400",
        "sunday": "null"
    },
    "annualVacationData": [
        {
            "month": "april",
            "days": "5"
        }
    ]
};

function IndustryCalculatorSteps() {

    const { t } = useTranslation();

    const [country, setCountry] = React.useState("rs");

    const [pills, setPills] = React.useState("1");
    const [latitude, setLatitude] = React.useState(null);
    const [longitude, setLongitude] = React.useState(null);
    const [surface, setSurface] = React.useState(null);
    const [aspect, setAspect] = React.useState(null);
    const [angle, setAngle] = React.useState(null);
    const [maximumAuthorizedPower, setMaximumAuthorizedPower] = React.useState(null);

    const [monthlyConsumption, setMonthlyConsumption] = React.useState({
        january: null,
        february: null,
        march: null,
        april: null,
        may: null,
        june: null,
        july: null,
        august: null,
        september: null,
        october: null,
        november: null,
        december: null,
    });

    const [workingHours, setWorkingHours] = React.useState({
        monday: "null",
        tuesday: "null",
        wednesday: "null",
        thursday: "null",
        friday: "null",
        saturday: "null",
        sunday: "null",
    });
    
    const [annualVacationData, setAnnualVacationData] = React.useState([{ "month": "none", "days": "none" }]);

    React.useEffect(() => {
        document.body.classList.add("basic-calculator");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("basic-calculator");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    function setLocation(latitude, longitude) {
        setLatitude(latitude);
        setLongitude(longitude);
    }

    function getLocation() {
        if (latitude == null || longitude == null) return t("calculatorSteps.location.inputFieldText");
        return MainService.degToDMS(latitude) + ", " + MainService.degToDMS(longitude);
    }

    function allowNextStep(tabPill) {
        switch (parseInt(tabPill)) {
            case 1:
                return latitude != null && longitude != null;
            case 2:
                return true;
            case 3:
                return true;
            case 4:
                return surface != null && surface != "";
            case 5:
                return Object.values(monthlyConsumption).every(element => element != null && element != "") &&
                    maximumAuthorizedPower != null &&
                    maximumAuthorizedPower != "";
            case 6:
                return true;
            case 7:
                for (let i = 0; i < annualVacationData.length; i++) {
                    const element = annualVacationData[i];
                    if (i == 0 && annualVacationData.length == 1 &&
                        element.month == "none" && element.days == "none")
                        return true;

                    if (element.month != "none" && (element.days == "none" || element.days == "")) return false;

                }
                return true;
            default:
                return false;
        }
    }

    function changeStep(tabPill) {
        setPills(tabPill);
    }

    function pillsProgres(tabPill) {
        if (tabPill == pills) return "active";
        if (tabPill < pills) return "completed";
        return "";
    }

    return (
        <>
            <Country
                country={country}
                setCountry={setCountry}
                pills={pills}
            />
            <Row>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <h2 className="title text-center">
                        {t("industryCalculator.steps")}
                    </h2>
                    <div className="nav-align-center">
                        <Nav
                            className="nav-pills-info nav-pills-just-icons"
                            pills
                            role="tablist"
                        >
                            <NavItem>
                                <NavLink
                                    className={pillsProgres("1")}
                                    onClick={(e) => {
                                        if ("1" < pills) {
                                            e.preventDefault();
                                            changeStep("1");
                                        }
                                    }}
                                    style={{ padding: "10px" }}
                                >
                                    <i className="now-ui-icons location"></i>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={pillsProgres("2")}
                                    onClick={(e) => {
                                        if ("2" < pills) {
                                            e.preventDefault();
                                            changeStep("2");
                                        }
                                    }}
                                    style={{ padding: "10px" }}
                                >
                                    <i className="now-ui-icons orientation"></i>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={pillsProgres("3")}
                                    onClick={(e) => {
                                        if ("3" < pills) {
                                            e.preventDefault();
                                            changeStep("3");
                                        }
                                    }}
                                    style={{ padding: "10px" }}
                                >
                                    <i className="now-ui-icons rooftops-slope"></i>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={pillsProgres("4")}
                                    onClick={(e) => {
                                        if ("4" < pills) {
                                            e.preventDefault();
                                            changeStep("4");
                                        }
                                    }}
                                    style={{ padding: "10px" }}
                                >
                                    <i className="now-ui-icons house-surface-area"></i>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={pillsProgres("5")}
                                    onClick={(e) => {
                                        if ("5" < pills) {
                                            e.preventDefault();
                                            changeStep("5");
                                        }
                                    }}
                                    style={{ padding: "10px" }}
                                >
                                    <i className="now-ui-icons average-bill"></i>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={pillsProgres("6")}
                                    onClick={(e) => {
                                        if ("6" < pills) {
                                            e.preventDefault();
                                            changeStep("6");
                                        }
                                    }}
                                    style={{ padding: "10px" }}
                                >
                                    <i className="now-ui-icons working"></i>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={pillsProgres("7")}
                                    onClick={(e) => {
                                        if ("7" < pills) {
                                            e.preventDefault();
                                            changeStep("7");
                                        }
                                    }}
                                    style={{ padding: "10px" }}
                                >
                                    <i className="now-ui-icons maintenance"></i>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <div className="progress-container progress-info">
                            <Progress max="100" value={(pills - 1) * 20} color={pills === 6 ? "success" : ""}>
                                <span className="progress-value">{(pills - 1) * 20}%</span>
                            </Progress>
                        </div>
                    </div>
                </Col>
                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                    <TabContent className="gallery" activeTab={"pills" + pills}>
                        <TabPane tabId="pills1">
                            <div className="basic-calculator-steps-card" style={{ minHeight: "600px" }}>
                                <Location setLocation={setLocation} getLocation={getLocation} />
                            </div>

                            <Row className="basic-calculator-steps-buttons">
                                <PreviousNextButtons changePill={changeStep} currentPill={pills} allowNextStep={allowNextStep} />
                            </Row>
                        </TabPane>
                        <TabPane tabId="pills2">
                            <div className="basic-calculator-steps-card">
                                <RoofOrientation setAspect={setAspect} />
                            </div>

                            <Row className="basic-calculator-steps-buttons">
                                <PreviousNextButtons changePill={changeStep} currentPill={pills} allowNextStep={allowNextStep} />
                            </Row>
                        </TabPane>
                        <TabPane tabId="pills3">
                            <div className="basic-calculator-steps-card">
                                <RooftopSlope setAngle={setAngle} />
                            </div>

                            <Row className="basic-calculator-steps-buttons">
                                <PreviousNextButtons changePill={changeStep} currentPill={pills} allowNextStep={allowNextStep} />
                            </Row>
                        </TabPane>
                        <TabPane tabId="pills4">
                            <div className="basic-calculator-steps-card">
                                <HouseSurface setSurface={setSurface} />
                            </div>
                            <Row className="basic-calculator-steps-buttons">
                                <PreviousNextButtons changePill={changeStep} currentPill={pills} allowNextStep={allowNextStep} />
                            </Row>
                        </TabPane>
                        <TabPane tabId="pills5">
                            <div className="basic-calculator-steps-card">
                                <BillData setMonthlyConsumption={setMonthlyConsumption} monthlyConsumption={monthlyConsumption} setMaximumAuthorizedPower={setMaximumAuthorizedPower} />
                            </div>
                            <Row className="basic-calculator-steps-buttons">
                                <PreviousNextButtons changePill={changeStep} currentPill={pills} allowNextStep={allowNextStep} />
                            </Row>
                        </TabPane>
                        <TabPane tabId="pills6">
                            <div className="basic-calculator-steps-card">
                                <WorkingHours setWorkingHours={setWorkingHours} workingHours={workingHours} />
                            </div>
                            <Row className="basic-calculator-steps-buttons">
                                <PreviousNextButtons changePill={changeStep} currentPill={pills} allowNextStep={allowNextStep} />
                            </Row>
                        </TabPane>
                        <TabPane tabId="pills7">
                            <div className="basic-calculator-steps-card">
                                <CollectiveAnnualVacation annualVacationData={annualVacationData} setAnnualVacationData={setAnnualVacationData} />
                            </div>
                            <Row className="basic-calculator-steps-buttons">
                                <PreviousNextButtons changePill={changeStep} currentPill={pills} allowNextStep={allowNextStep} />
                            </Row>
                        </TabPane>
                        <TabPane tabId="pills8" className="results">
                            <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                <IndustryCalculatorResults
                                    start={pills == 8}
                                    inputValues={pills == 8
                                        ? {
                                            latitude,
                                            longitude,
                                            surface,
                                            aspect,
                                            angle,
                                            monthlyConsumption,
                                            maximumAuthorizedPower,
                                            workingHours,
                                            annualVacationData,
                                        }
                                        : {}}
                                    // inputValues={
                                    //     pills == 8
                                    //         ? testDataPuno
                                    //         : {}
                                    // }
                                    country={country}
                                />
                            </Col>
                        </TabPane>
                    </TabContent>
                </Col>
            </Row>
        </>
    );

}

function PreviousNextButtons(props) {

    const { t } = useTranslation();

    return (
        <div className="float-right">
            {
                props.currentPill == "1"
                    ? <></>
                    : <Button
                        className="basic-calculator-steps-button btn-round"
                        color="info"
                        type="button"
                        onClick={() => props.changePill(parseInt(props.currentPill) - 1)}
                        style={{ float: "left" }}
                    >
                        <i className="now-ui-icons arrows-1_minimal-left"></i>
                        {t("stringsTranslations.previousStep")}
                    </Button>
            }
            {
                props.currentPill == "7"
                    ? <Button
                        disabled={!props.allowNextStep(props.currentPill)}
                        className="basic-calculator-steps-button btn-round"
                        color="info"
                        type="button"
                        onClick={() => props.changePill(parseInt(8))}
                        style={{ float: "right" }}
                    >
                        {t("stringsTranslations.finish")}
                        <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </Button>
                    : <Button
                        disabled={!props.allowNextStep(props.currentPill)}
                        className="basic-calculator-steps-button btn-round"
                        color="info"
                        type="button"
                        onClick={() => props.changePill(parseInt(props.currentPill) + 1)}
                        style={{ float: "right" }}
                    >
                        {t("stringsTranslations.nextStep")}
                        <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </Button>
            }
        </div>
    );
}

export default IndustryCalculatorSteps;
