import React from "react";

// reactstrap components
import { Container, Row, Col, Button } from "reactstrap";
import animationData from 'assets/lotties/solar-energy-benefits.json';
import Lottie from "react-lottie";
import { useTranslation } from "react-i18next";

// core components

function Introduction() {

  const { t } = useTranslation();


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <>
      <div className="section section-introduction">
        <Container>
          <Row>
            <Col lg="6" md="12">
              {/* <h2 className="title">{t("home.introduction.title")}</h2> */}
              <h2 className="title">{t("home.softwareForDesignCompanies.title")}</h2>
              <h5 className="description">
                {/* {t("home.introduction.description")} */}
                {t("home.softwareForDesignCompanies.description")}
              </h5>
              <Button
                className="btn-round"
                color="info"
                href="/software"
                outline
                size="lg"
              >
                {t("home.softwareForDesignCompanies.button")}
              </Button>
            </Col>
            <Col lg="6" md="12">
              <div className="icons-container">
                <Lottie
                  options={defaultOptions}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Introduction;
