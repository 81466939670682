import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

// core components
import SiteNavbar from "components/Navbars/SiteNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import HouseholdsCalculatorHeader from "components/HouseholdsCalculator/HouseholdsCalculatorHeader";
import HouseholdsCalculatorSteps from "components/HouseholdsCalculator/HouseholdsCalculatorSteps";

import ".././assets/css/solarlink-style/basic-calculator.css";
import { useTranslation } from "react-i18next";

function HouseholdsCalculatorPage() {

  const { t } = useTranslation();

  React.useEffect(() => {
    document.body.classList.add("basic-calculator");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("basic-calculator");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <SiteNavbar />
      <div className="wrapper">
        <HouseholdsCalculatorHeader />
        <div className="section" style={{ padding: "0" }} >
          <Container>
            <div style={{ marginBottom: "40px" }}>
              <h2 className="title">
                {t("householdCalculator.title")}
              </h2>
              <h4 className="description">
                {t("householdCalculator.description1")}
              </h4>
              <h4 className="description">
                {t("householdCalculator.description2")}
              </h4>
              <h4 className="description">
                <i>
                  {t("householdCalculator.note")}
                </i>
              </h4>
            </div>
            <HouseholdsCalculatorSteps />
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default HouseholdsCalculatorPage;
