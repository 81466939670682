import React, { Component, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useCurrentPng } from 'recharts-to-png';
import { useCallback } from 'react';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{label}</p>
                <p className="desc">{`Proizvodnja: ${Math.round(payload[0].value)}kWh`}</p>
            </div>
        );
    }

    return null;
};

// Python vraca podatke u formatu:
// {
//     "results": {
//         "months": [
//             {
//                 "month": "Januar",
//                 "E_m": 0.0
//             },
//             ...
//         ]
//     }
// }
// Zato prevodim mesece u potrebni jezik
function translateMonths(data) {
    let out = [];
    for (let i = 0; i < data.length; i++) {
        const element = data[i];
        switch (data[i].month) {
            case "January":
                out.push({ "month": "Jan", "E_m": element.E_m });
                break;
            case "February":
                out.push({ "month": "Feb", "E_m": element.E_m });
                break;
            case "March":
                out.push({ "month": "Mart", "E_m": element.E_m });
                break;
            case "April":
                out.push({ "month": "Apr", "E_m": element.E_m });
                break;
            case "May":
                out.push({ "month": "Maj", "E_m": element.E_m });
                break;
            case "June":
                out.push({ "month": "Jun", "E_m": element.E_m });
                break;
            case "July":
                out.push({ "month": "Jul", "E_m": element.E_m });
                break;
            case "August":
                out.push({ "month": "Avg", "E_m": element.E_m });
                break;
            case "September":
                out.push({ "month": "Sep", "E_m": element.E_m });
                break;
            case "October":
                out.push({ "month": "Okt", "E_m": element.E_m });
                break;
            case "November":
                out.push({ "month": "Nov", "E_m": element.E_m });
                break;
            case "December":
                out.push({ "month": "Dec", "E_m": element.E_m });
                break;

            default:
                out.push(element);
                break;
        }
    }
    return out;
}

function ProductionConsumptionGraph(props) {

    // useCurrentPng usage (isLoading is optional)
    const [getPng, { ref }] = useCurrentPng();

    useEffect(() => {
        console.log("pcg")
        setGraphDataToParent();
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            if (setGraphDataToParent()) {
                clearInterval(timer);
            }
        }, 1000);
    }, []);

    const setGraphDataToParent = useCallback(async () => {

        const png = await getPng();
        props.setPNG(png);

        return png != undefined;

    }, [getPng]);

    return (
        <>
            {props.data != null
                ? <ResponsiveContainer width="110%" height={300}>
                    <LineChart
                        ref={ref}
                        // width={960}
                        // height={400}
                        data={translateMonths(props.data.results.months)}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                    >
                        <CartesianGrid horizontal={true} vertical={false} strokeDasharray="1" />
                        <XAxis dataKey="month" angle={0} dx={0} />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <Line
                            type="monotone"
                            dataKey="E_m"
                            stroke="#fcc11b"
                            strokeWidth="5px"
                            activeDot={{ r: 8, fill: "#fcc11b" }}
                            name="Proizvodnja električne energije tokom godine u kWh"
                        />
                        {/* <Line
                        type="monotone"
                        dataKey="consumption"
                        stroke="#225876"
                        strokeWidth="5px"
                        activeDot={{ r: 8, fill: "#225876" }}
                    /> */}
                    </LineChart>
                </ResponsiveContainer>
                : <>
                </>
            }
        </>
    );
}

export default ProductionConsumptionGraph;