import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
    Row,
    Col,
} from "reactstrap";
import MainService from 'services/MainService';
import { Button, InputAdornment, OutlinedInput, Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

// import iconSet from "../../assets/icon-sets/selection.json";
import iconSet from "../../../assets/icon-sets/selection.json";
import IcomoonReact from "icomoon-react";

import { t } from 'i18next';

function RoofsDataInputForm(props) {

    const handleRoofRemove = (index) => {
        const list = [...props.roofsData];
        list.splice(index, 1);
        props.setRoofsData(list);
    };

    const handleRoofAdd = () => {
        props.setRoofsData([...props.roofsData, { "azimuthAngle": "none", "tiltAngle": "none", "freeRoofArea": "none" }]);
    };


    return (
        <>
            {console.log("=======213123========")}
            {console.log("=======213123========")}
            {console.log("=======213123========")}
            {console.log(props.roofsData)}
            {console.log(props.roofsData)}
            {console.log(props.roofsData)}
            {console.log(props.roofsData)}
            {console.log("=======213123========")}
            {console.log("=======213123========")}
            {console.log("=======213123========")}
            {
                props.roofsData.map((singleRoof, index) => {
                    return <RoofDataInputFormElement
                        index={index}
                        handleRoofRemove={handleRoofRemove}
                    />

                })
            }

            {/* <Stack direction="row" spacing={1}>
                <IconButton style={{ color: "#fcc11b" }} onClick={handleRoofAdd}>
                    <AddCircleOutlineIcon />
                </IconButton>
                <IconButton style={{ color: "#fcc11b" }} onClick={handleRoofRemove}>
                    <RemoveCircleOutlineIcon />
                </IconButton>
            </Stack> */}


            <Tooltip title={<span className="tooltip-text">Add new roof</span>}>
                <IconButton onClick={handleRoofAdd}>
                    <IcomoonReact
                        iconSet={iconSet}
                        // color={navIconsColor} 
                        size={32}
                        icon="plus"
                        style={{ paddingRight: "10px" }}
                    />
                </IconButton>
            </Tooltip>

        </>
    );

}

function RoofDataInputFormElement(props) {

    return (
        <>
            <div className="roofs-input-area-grid">

                <div className="enterprise-input-field">
                    <span>{t("calculatorSteps.azimuthAngle.inputFieldText")}</span>
                    <FormControl>
                        <OutlinedInput
                            endAdornment={<InputAdornment position="end">°</InputAdornment>}
                        />
                    </FormControl>
                </div>

                <div className="enterprise-input-field">
                    <span>{t("calculatorSteps.tiltAngle.inputFieldText")}</span>
                    <FormControl>
                        <OutlinedInput
                            endAdornment={<InputAdornment position="end">°</InputAdornment>}
                            type="number"
                        />
                    </FormControl>
                </div>

                <div className="enterprise-input-field">
                    <span>{t('calculatorSteps.freeRoofArea.inputFieldText')}</span>
                    <FormControl>
                        <OutlinedInput
                            // value={values.amount}
                            // onChange={handleChange('surface')}
                            endAdornment={<InputAdornment position="end">m<sup>2</sup></InputAdornment>}
                            type="number"
                        />
                    </FormControl>
                </div>

                <IconButton onClick={props.handleRoofRemove}>
                    <IcomoonReact
                        iconSet={iconSet}
                        // color={navIconsColor} 
                        size={32}
                        icon="remove"
                        style={{ paddingRight: "10px" }}
                    />
                </IconButton>

            </div>

        </>
    );
}

export default RoofsDataInputForm;