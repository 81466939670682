import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

// core components
import SiteNavbar from "components/Navbars/SiteNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import HouseholdsCalculatorHeader from "components/HouseholdsCalculator/HouseholdsCalculatorHeader";
import IndustryCalculatorSteps from "components/IndustryCalculator/IndustryCalculatorSteps";
import Country from "components/CalculatorSteps/Country";

import ".././assets/css/solarlink-style/basic-calculator.css";
import ".././assets/css/solarlink-style/industry-calculator.css";

import { t } from 'i18next';

function IndustryCalculatorPage() {

  React.useEffect(() => {
    document.body.classList.add("basic-calculator");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("basic-calculator");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <SiteNavbar />
      <div className="wrapper">
        <HouseholdsCalculatorHeader />
        <div className="section" style={{ padding: "0" }} >
          <Container>
            <div style={{ marginBottom: "40px" }}>
              <h2 className="title">
                {t("industryCalculator.title")}
              </h2>
              <h4 className="description">
                {t("industryCalculator.description1")}
              </h4>
              <h4 className="description">
                {t("industryCalculator.description2")}
              </h4>
              <h4 className="description">
                <i>
                  {t("industryCalculator.note")}
                </i>
              </h4>
            </div>
            <IndustryCalculatorSteps />
          </Container>
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}

export default IndustryCalculatorPage;
