import * as React from 'react';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import MainService from 'services/MainService';


function Country(props) {

    const { t } = useTranslation();

    const countries = MainService.getAvailableCountriesForCalculator();

    const handleCountryChange = (event) => {
        props.setCountry(event.target.value);
    };

    return (
        <>
            <div className='basic-calculator-start-container'>

                <TextField fullWidth sx={{ m: 1 }}
                    // disabled={props.pills != 1}
                    id="outlined-select-country-native"
                    select
                    label={t("calculatorSteps.countrySelect")}
                    value={props.country}
                    onChange={handleCountryChange}
                    SelectProps={{
                        native: true,
                    }}
                    helperText={t("calculatorSteps.countrySelectExplanation")}
                    style={{ maxWidth: '500px' }}
                >
                    {countries.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </TextField>

            </div>
        </>
    );

}
export default Country;