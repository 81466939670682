import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import {
    Row,
    Col,
} from "reactstrap";

import { t } from 'i18next';

function BillData(props) {

    const handleMonthlyConsumptionChange = (event, month) => {
        if (event.target.value < 0) {
            event.target.value = "";
        }

        props.setMonthlyConsumption({ ...props.monthlyConsumption, [month]: event.target.value });
    }

    const handleMaximumAuthorizedPowerChange = (event) => {
        if (event.target.value < 0) {
            event.target.value = "";
        }

        props.setMaximumAuthorizedPower(event.target.value);
    }

    return (
        <>
            <Row className="justify-content-center">
                <h3 className="title text-center">
                {t("calculatorSteps.monthlyElectricityConsumption.title")}
                </h3>
            </Row>
            <Row className='basic-calculator-steps-body'>
                <Col className='monthly-consumption-input' xs="12" sm="12" md="5" lg="5" xl="5">
                    <div className='monthly-consumption-input-left'>
                        <TextField
                            placeholder={t("months.january")}
                            type="number"

                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(event) => handleMonthlyConsumptionChange(event, "january")}
                        />
                        <TextField
                            placeholder={t("months.february")}
                            type="number"

                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(event) => handleMonthlyConsumptionChange(event, "february")}
                        />
                        <TextField
                            placeholder={t("months.march")}
                            type="number"

                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(event) => handleMonthlyConsumptionChange(event, "march")}
                        />
                        <TextField
                            placeholder={t("months.april")}
                            type="number"

                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(event) => handleMonthlyConsumptionChange(event, "april")}
                        />
                        <TextField
                            placeholder={t("months.may")}
                            type="number"

                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(event) => handleMonthlyConsumptionChange(event, "may")}
                        />
                        <TextField
                            placeholder={t("months.june")}
                            type="number"

                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(event) => handleMonthlyConsumptionChange(event, "june")}
                        />
                    </div>
                    <div className='monthly-consumption-input-right'>
                        <TextField
                            placeholder={t("months.july")}
                            type="number"

                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(event) => handleMonthlyConsumptionChange(event, "july")}
                        />
                        <TextField
                            placeholder={t("months.august")}
                            type="number"

                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(event) => handleMonthlyConsumptionChange(event, "august")}
                        />
                        <TextField
                            placeholder={t("months.september")}
                            type="number"

                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(event) => handleMonthlyConsumptionChange(event, "september")}
                        />
                        <TextField
                            placeholder={t("months.october")}
                            type="number"

                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(event) => handleMonthlyConsumptionChange(event, "october")}
                        />
                        <TextField
                            placeholder={t("months.november")}
                            type="number"

                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(event) => handleMonthlyConsumptionChange(event, "november")}
                        />
                        <TextField
                            placeholder={t("months.december")}
                            type="number"

                            InputProps={{ inputProps: { min: 0 } }}
                            onChange={(event) => handleMonthlyConsumptionChange(event, "december")}
                        />
                    </div>
                    <TextField
                        className='maximum-authorized-power'
                        label={t("calculatorSteps.monthlyElectricityConsumption.inputFieldText")}
                        type="number"
                        InputProps={{ inputProps: { min: 0 } }}
                        onChange={(event) => handleMaximumAuthorizedPowerChange(event)}
                    />
                </Col>
                <Col className='average-bill-description' xs="12" sm="12" md="7" lg="7" xl="7" style={{ flexWrap: "wrap", flexDirection: "row-reverse" }}>
                    <h5>
                    {t("calculatorSteps.monthlyElectricityConsumption.description")}
                    </h5>
                </Col>
            </Row>
        </>
    );

}
export default BillData;