import React, { Component } from 'react';
import { withGoogleMap, GoogleMap, withScriptjs, Marker } from "react-google-maps";
import Autocomplete from 'react-google-autocomplete';
import Geocode from "react-geocode";
// import AutoComplete from 'places-autocomplete-react'
import {
    Row,
    Col,
} from "reactstrap";

Geocode.setApiKey("AIzaSyAyizS0BBMzz-xKJZ94h39L8zBLpCC4tok");
Geocode.enableDebug();

function formatAddress(place) {
    let street = "",
        city = "",
        state = "";

    for (let i = 0; i < place.address_components.length; i++) {
        if (place.address_components[i].types.includes("route")) {
            street = place.address_components[i].long_name;
        }
        if (place.address_components[i].types.includes("locality")) {
            city = place.address_components[i].long_name;
        }
        if (place.address_components[i].types.includes("country")) {
            state = place.address_components[i].long_name;
        }
    }
    return `${street}, ${city}, ${state}`;
}

class Map extends Component {

    constructor(props) {
        super(props);
        this.state = {
            address: '',
            city: '',
            area: '',
            state: '',
            country: '',
            mapPosition: {
                lat: this.props.center.lat,
                lng: this.props.center.lng
            },
            markerPosition: {
                lat: this.props.center.lat,
                lng: this.props.center.lng
            },
            setLocation: props.setLocation,
            formatedAddress: ''
        }
    }

    /**
      * Get the current address from the default map position and set those values in the state
      */
    componentDidMount() {

        this.autocomplete = new window.google.maps.places.Autocomplete(
            document.getElementById('autocomplete'),
            {
                componentRestrictions: { country: "srb" },
                fields: ["address_components", "geometry"]
            }
        )
        this.autocomplete.addListener("place_changed", this.handlePlaceSelect)

        Geocode.fromLatLng(this.state.mapPosition.lat, this.state.mapPosition.lng).then(
            response => {
                const address = response.results[0].formatted_address,
                    addressArray = response.results[0].address_components,
                    city = this.getCity(addressArray),
                    area = this.getArea(addressArray),
                    state = this.getState(addressArray),
                    country = this.getCountry(addressArray);

                this.setState({
                    address: (address) ? address : '',
                    area: (area) ? area : '',
                    city: (city) ? city : '',
                    state: (state) ? state : '',
                    country: (country) ? country : '',
                })
            },
            error => {
                console.error(error);
            }
        );


        //Set interval
        this.interval = setInterval(() => {
            if (document.getElementsByClassName("gmnoprint")[0]) {
                document.getElementsByClassName("gmnoprint")[0].classList.add("dodato");
                clearInterval(this.interval);
            }
        }, 200);
    };

    /**
      * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
      *
      * @param nextProps
      * @param nextState
      * @return {boolean}
      */
    shouldComponentUpdate(nextProps, nextState) {
        if (
            this.state.markerPosition.lat !== this.props.center.lat ||
            this.state.address !== nextState.address ||
            this.state.city !== nextState.city ||
            this.state.area !== nextState.area ||
            this.state.state !== nextState.state
        ) {
            return true
        } else if (this.props.center.lat === nextProps.center.lat) {
            return false
        }
    }

    /**
      * Get the city and set the city input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getCity = (addressArray) => {
        let city = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
                city = addressArray[i].long_name;
                return city;
            }
        }
    };

    /**
      * Get the area and set the area input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getArea = (addressArray) => {
        let area = '';
        for (let i = 0; i < addressArray.length; i++) {
            if (addressArray[i].types[0]) {
                for (let j = 0; j < addressArray[i].types.length; j++) {
                    if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
                        area = addressArray[i].long_name;
                        return area;
                    }
                }
            }
        }
    };

    /**
      * Get the state and set the state input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getState = (addressArray) => {
        let state = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                    state = addressArray[i].long_name;
                    return state;
                }
            }
        }
    };

    /**
      * Get the country and set the country input value to the one selected
      *
      * @param addressArray
      * @return {string}
      */
    getCountry = (addressArray) => {
        let country = '';
        for (let i = 0; i < addressArray.length; i++) {
            for (let i = 0; i < addressArray.length; i++) {
                if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
                    country = addressArray[i].long_name;
                    return country;
                }
            }
        }
    };

    /**
      * And function for city,state and address input
      * @param event
      */
    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    /**
      * When the user types an address in the search box
      * @param place
    */
    onPlaceSelected = (place) => {
        // const address = place.formatted_address,
        const address = formatAddress(place),
            addressArray = place.address_components,
            city = this.getCity(addressArray),
            area = this.getArea(addressArray),
            state = this.getState(addressArray),
            country = this.getCountry(addressArray),
            latValue = place.geometry.location.lat(),
            lngValue = place.geometry.location.lng();
        // Set these values in the state.
        this.setState({
            address: (address) ? address : '',
            area: (area) ? area : '',
            city: (city) ? city : '',
            state: (state) ? state : '',
            country: (country) ? country : '',
            markerPosition: {
                lat: latValue,
                lng: lngValue
            },
            mapPosition: {
                lat: latValue,
                lng: lngValue
            },
        });
        console.log(place);
        this.state.setLocation(latValue, lngValue);
    };

    /**
      * When the marker is dragged you get the lat and long using the functions available from event object.
      * Use geocode to get the address, city, area and state from the lat and lng positions.
      * And then set those values in the state.
      *
      * @param event
      */
    onMarkerDragEnd = (event) => {

        let newLat = event.latLng.lat(),
            newLng = event.latLng.lng(),
            addressArray = [];
        Geocode.fromLatLng(newLat, newLng).then(
            response => {
                const address = response.results[0].formatted_address,
                    addressArray = response.results[0].address_components,
                    city = this.getCity(addressArray),
                    area = this.getArea(addressArray),
                    state = this.getState(addressArray),
                    country = this.getCountry(addressArray);
                this.setState({
                    address: (address) ? address : '',
                    area: (area) ? area : '',
                    city: (city) ? city : '',
                    state: (state) ? state : '',
                    country: (country) ? country : '',
                    mapPosition: {
                        lat: newLat,
                        lng: newLng
                    },
                    markerPosition: {
                        lat: newLat,
                        lng: newLng
                    }
                })
                this.state.setLocation(newLat, newLng);
            },
            error => {
                console.error(error);
            }
        );
    };

    render() {
        const AsyncMap = withScriptjs(
            withGoogleMap(
                props => (
                    <GoogleMap google={this.props.google}
                        defaultZoom={this.props.zoom}
                        defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
                        options={{ mapTypeId: "hybrid", mapTypeControl: true, streetViewControl: false }}
                        onClick={this.onMarkerDragEnd}
                        className="google-map"
                    >
                        {/* For Auto complete Search Box */}
                        <Autocomplete
                            style={{
                                width: '100%',
                                height: '40px',
                                paddingLeft: '16px',
                                marginTop: '2px',
                            }}
                            options={{
                                // types: ['(cities)', 'address'],
                                // types: ['(regions)'],
                                types: ['address'],
                                componentRestrictions: { country: "srb" },
                                fields: ["address_components", "geometry"],
                            }}
                            onPlaceSelected={this.onPlaceSelected}
                        />
                        {/*Marker*/}
                        <Marker google={this.props.google}
                            name={'Dolores park'}
                            draggable={true}
                            onDragEnd={this.onMarkerDragEnd}
                            position={{
                                lat: this.state.markerPosition.lat,
                                lng: this.state.markerPosition.lng
                            }}
                        />
                        <Marker />
                    </GoogleMap>
                )
            )
        );

        return (<>
            <AsyncMap
                googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAyizS0BBMzz-xKJZ94h39L8zBLpCC4tok&libraries=places"
                loadingElement={
                    <div style={{ height: '100%', width: '100%' }} />
                }
                containerElement={
                    <div style={{ height: this.props.height, width: this.props.width }} />
                }
                mapElement={
                    <div style={{ height: '100%', width: '100%' }} />
                }
            />
        </>)
    }

}
export default Map