import * as React from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Typography from "@mui/joy/Typography";
import Check from "@mui/icons-material/Check";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import { t } from "i18next";

export default function PricingCards() {
  function scrollToAnchor() {
    window.location.href = "/software#contact-container2";
  }
  return (
    <Box
  style={{
    width: "100%",
    display: "grid",
    gap: 2,
    marginBottom: "80px",
  }}
  sx={{
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: 2,
    '@media (min-width: 768px)': {
      gridTemplateColumns: "1fr 1fr", // For tablets and larger screens
    },
  }}
>
      <Card size="lg" variant="outlined">
        {/* <Chip size="sm" variant="outlined" color="neutral">
          BASIC
        </Chip> */}
        <Typography level="h2">{t("stringsTranslations.monthly")}</Typography>
        <Divider inset="none" />
        <List size="sm" sx={{ mx: "calc(-1 * var(--ListItem-paddingX))" }}>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            {t("softwareForDesignCompanies.pricing.bulletPoint1")}
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            {t("softwareForDesignCompanies.pricing.bulletPoint2")}
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            {t("softwareForDesignCompanies.pricing.bulletPoint3")}
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            {t("softwareForDesignCompanies.pricing.bulletPoint4")}
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            {t("softwareForDesignCompanies.pricing.bulletPoint5")}
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            {t("softwareForDesignCompanies.pricing.bulletPoint6")}
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            {t("softwareForDesignCompanies.pricing.bulletPoint7")}
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            {t("softwareForDesignCompanies.pricing.bulletPoint8")}
          </ListItem>
        </List>
        <Divider inset="none" />
        <CardActions>
          <Typography level="title-lg" sx={{ mr: "auto" }}>
            99.99 €{" "}
            <Typography fontSize="sm" textColor="text.tertiary">
              / {t("months.month")}
            </Typography>
          </Typography>
          <Button
            variant="soft"
            color="neutral"
            endDecorator={<KeyboardArrowRight />}
            onClick={scrollToAnchor}
          >
            {t("softwareForDesignCompanies.startNow")}
          </Button>
        </CardActions>
      </Card>
      <Card
        size="lg"
        variant="solid"
        color="neutral"
        invertedColors
        sx={{ bgcolor: "#2c2c2c" }}
      >
        {/* <Chip size="sm" variant="outlined">
          MOST POPULAR
        </Chip> */}
        <Typography level="h2">{t("stringsTranslations.annually")}</Typography>
        <Divider inset="none" />
        <List
          size="sm"
          sx={{ mx: "calc(-1 * var(--ListItem-paddingX))" }}
          //   sx={{
          //     display: "grid",
          //     gridTemplateColumns: "1fr 1fr",
          //     mx: "calc(-1 * var(--ListItem-paddingX))",
          //   }}
        >
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            {t("softwareForDesignCompanies.pricing.bulletPoint1")}
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            {t("softwareForDesignCompanies.pricing.bulletPoint2")}
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            {t("softwareForDesignCompanies.pricing.bulletPoint3")}
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            {t("softwareForDesignCompanies.pricing.bulletPoint4")}
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            {t("softwareForDesignCompanies.pricing.bulletPoint5")}
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            {t("softwareForDesignCompanies.pricing.bulletPoint6")}
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            {t("softwareForDesignCompanies.pricing.bulletPoint7")}
          </ListItem>
          <ListItem>
            <ListItemDecorator>
              <Check />
            </ListItemDecorator>
            {t("softwareForDesignCompanies.pricing.bulletPoint8")}
          </ListItem>
        </List>
        <Divider inset="none" />
        <CardActions>
          <Typography level="title-lg" sx={{ mr: "auto" }}>
            <strike>99.99 €</strike>
            <br></br>
            79.99 €{" "}
            <Typography fontSize="sm" textColor="text.tertiary">
              / {t("months.month")}
            </Typography>
          </Typography>
          <Button
            endDecorator={<KeyboardArrowRight />}
            onClick={scrollToAnchor}
          >
            {t("softwareForDesignCompanies.startNow")}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
