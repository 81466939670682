import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import {
    Row,
    Col,
} from "reactstrap";
import { t } from 'i18next';

function AverageBill(props) {

    const [values, setValues] = React.useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const [currency, setCurrency] = React.useState('EUR');

    const handleChangeCurrency = (event) => {
        setCurrency(event.target.value);
    };

    const handleChange = (prop) => (event) => {
        props.setConsumption(event.target.value);
        setValues({ ...values, [prop]: event.target.value });
    };

    return (
        <>
            <Row className="justify-content-center">
                <h3 className="title text-center">
                    {t("calculatorSteps.averageBill.title")}
                </h3>
            </Row>
            <Row className='basic-calculator-steps-body'>
                <Col className='average-bill-input' xs="12" sm="12" md="4" lg="4" xl="4">
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <img
                            alt="average-bill"
                            src={require("assets/img/basic calculator/average-bill.png")}
                            style={{ maxWidth: "250px" }}
                        ></img>
                    </Box>
                </Col>
                <Col className='average-bill-description' xs="12" sm="12" md="8" lg="8" xl="8" style={{ flexWrap: "wrap", flexDirection: "row-reverse" }}>
                    <h5>
                    {t("calculatorSteps.averageBill.description")}
                    </h5>
                    <FormControl sx={{ m: 1 }}>
                        <InputLabel htmlFor="outlined-adornment-amount">{t("calculatorSteps.averageBill.inputFieldText")}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-amount"
                            value={values.amount}
                            onChange={handleChange('amount')}
                            endAdornment={<InputAdornment position="end">kWh</InputAdornment>}
                            label="Annualy consumption"
                            type="number"
                        />
                    </FormControl>

                </Col>
            </Row>
        </>
    );

}
export default AverageBill;