import React from "react";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Col
} from "reactstrap";

import ButtonMu from '@mui/material/Button';

function LoginForm(props) {

    const [firstFocus, setFirstFocus] = React.useState(false);
    const [lastFocus, setLastFocus] = React.useState(false);


    React.useEffect(() => {
        document.body.classList.add("login-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("login-page");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);


    return (
        <>
            <div>
                <Container>
                    <Col className="col-ml-12 col-mr-12 col-md-12">
                        <Card className="card-login card-plain">
                            <Form action="" className="form" method="">
                                <CardHeader className="text-center">
                                    <div className="logo-container">
                                        <img
                                            alt="solarlink"
                                            src={require("assets/img/solarlink-full-blue.png")}
                                        ></img>
                                    </div>
                                </CardHeader>
                                <CardBody style={{ paddingBottom: "0" }}>
                                    <InputGroup
                                        className={
                                            "no-border input-lg" +
                                            (firstFocus ? " input-group-focus" : "")
                                        }
                                    >
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-envelope"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Email"
                                            type="text"
                                            onFocus={() => setFirstFocus(true)}
                                            onBlur={() => setFirstFocus(false)}
                                        ></Input>
                                    </InputGroup>
                                    <InputGroup
                                        className={
                                            "no-border input-lg" +
                                            (lastFocus ? " input-group-focus" : "")
                                        }
                                    >
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fas fa-key"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Password"
                                            type="text"
                                            onFocus={() => setLastFocus(true)}
                                            onBlur={() => setLastFocus(false)}
                                        ></Input>
                                    </InputGroup>
                                </CardBody>
                                <CardFooter className="text-center" style={{ marginTop: "0" }}>
                                    <Button
                                        block
                                        className="btn-round"
                                        color="info"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                        size="lg"
                                    >
                                        Get Started
                                    </Button>
                                    <div className="pull-left">
                                        <h6>
                                            <ButtonMu className="link" style={{ color: "white" }} onClick={props.changeForm}>Create Account</ButtonMu>
                                        </h6>
                                    </div>
                                    <div className="pull-right">
                                        <h6>
                                            <ButtonMu className="link" style={{ color: "white" }}>Need Help?</ButtonMu>
                                        </h6>
                                    </div>
                                </CardFooter>
                            </Form>
                        </Card>
                    </Col>
                </Container>
            </div>
        </>
    );

}

export default LoginForm;
