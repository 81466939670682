import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
    Row,
    Col,
} from "reactstrap";
import MainService from 'services/MainService';
import { Button, InputAdornment, OutlinedInput } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { t } from 'i18next';

function CollectiveAnnualVacation(props) {

    const handleMonthRemove = (index) => {
        const list = [...props.annualVacationData];
        list.splice(index, 1);
        props.setAnnualVacationData(list);
    };

    const handleMonthAdd = () => {
        props.setAnnualVacationData([...props.annualVacationData, { "month": "none", "days": "none" }]);
    };

    const handleMonthSelect = (month, index) => {
        const list = [...props.annualVacationData];
        list[index].month = month;
        props.setAnnualVacationData(list);
    };

    const handleDayInput = (days, index) => {
        const list = [...props.annualVacationData];
        list[index].days = days;
        props.setAnnualVacationData(list);
    };

    const getAvailableMonths = (selectedMonth) => {
        return MainService.getMonths().filter(month => !props.annualVacationData.some(x => month === x.month && month !== selectedMonth))
    }

    return (
        <>
            <Row className="justify-content-center">
                <h3 className="title text-center">
                    {t('calculatorSteps.collectiveAnnualVacation.title')}
                </h3>
            </Row>
            <Row className='basic-calculator-steps-body'>
                <Col className='collective-annual-vacation-input' xs="12" sm="12" md="5" lg="5" xl="5">

                    {
                        props.annualVacationData.map((singleMonth, index) => (
                            <CollectiveAnnualVacationInputElement 
                            index={index}
                            getAvailableMonths={getAvailableMonths}
                            handleMonthSelect={handleMonthSelect}
                            handleDayInput={handleDayInput}
                            />
                        ))
                    }

                    <Stack direction="row" spacing={1}>
                        <IconButton style={{ color: "#fcc11b" }} onClick={handleMonthAdd}>
                            <AddCircleOutlineIcon />
                        </IconButton>
                        <IconButton style={{ color: "#fcc11b" }} onClick={handleMonthRemove}>
                            <RemoveCircleOutlineIcon />
                        </IconButton>
                    </Stack>
                </Col>
                <Col className='collective-annual-vacation-description' xs="12" sm="12" md="7" lg="7" xl="7" style={{ flexWrap: "wrap", flexDirection: "row-reverse" }}>
                    <h5>
                    {t('calculatorSteps.collectiveAnnualVacation.description')}
                    </h5>
                </Col>
            </Row>
        </>
    );

}

function CollectiveAnnualVacationInputElement(props) {

    const [selectedMonth, setSelectedMonth] = React.useState('none');
    const [numberOfDays, setNumberOfDays] = React.useState("none");
    const [maxDays, setMaxDays] = React.useState(0);


    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
        setMaxDays(MainService.getDaysInMonth(event.target.value));
        props.handleMonthSelect(event.target.value, props.index);
    };

    const handleDaysChange = (event) => {
        if (event.target.value > maxDays) {
            event.target.value = maxDays;
        }
        if (event.target.value < 0) {
            event.target.value = 0;
        }

        props.handleDayInput(event.target.value, props.index);
        setNumberOfDays(event.target.value);
    };

    return (
        <>
            <FormControl>
                <InputLabel htmlFor="collective-annual-vacation-month-label">{t("month")}</InputLabel>
                <Select
                    id="collective-annual-vacation-month-label"
                    onChange={handleMonthChange}
                    autoWidth
                    label={t("months.month")}
                >
                    <MenuItem value="none">{t("calculatorSteps.collectiveAnnualVacation.none")}</MenuItem>
                    {
                        props.getAvailableMonths(selectedMonth).map((day, index) =>
                            <MenuItem value={day}>{t(day)}</MenuItem>
                        )
                    }
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel htmlFor="collective-annual-vacation-days-label">{t('calculatorSteps.collectiveAnnualVacation.inputFieldText')}</InputLabel>
                <OutlinedInput
                    id="collective-annual-vacation-days-label"
                    disabled={selectedMonth === '' || selectedMonth === 'none'}
                    value={numberOfDays}
                    onChange={handleDaysChange}
                    label="Broj neradnih dana"
                    type="number"
                />
            </FormControl>
        </>
    );
}

export default CollectiveAnnualVacation;