import i18n from "i18next";
import React from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Flag from "react-world-flags";
import iconSet from "../../assets/icon-sets/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";

// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

const languages = [{
  code: 'en',
  name: 'English',
  country_code: 'gb'
},
{
  code: 'srb',
  name: 'Srpski',
  country_code: 'srb'
},
];

function SiteNavbar() {

  const { t } = useTranslation();
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [navLogo, setNavLogo] = React.useState(require("assets/img/solarlink-full-yellow.png"));
  const [globeColor, setGlobeColor] = React.useState("#fcc11b");
  const [navIconsColor, setNavIconsColor] = React.useState("#fcc11b");


  const GlobeIcon = ({ width = 35, height = 35 }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={globeColor}
      className="bi bi-globe"
      viewBox="0 0 16 16"
    >
      <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z" />
    </svg>
  );

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 399 || document.body.scrollTop > 399) {

        setNavbarColor("");
        setNavLogo(require("assets/img/solarlink-full-blue.png"));
        setGlobeColor("#094b6e");
        setNavIconsColor("#094b6e");

      } else if (document.documentElement.scrollTop < 400 || document.body.scrollTop < 400) {

        setNavbarColor("navbar-transparent");
        setNavLogo(require("assets/img/solarlink-full-yellow.png"));
        setGlobeColor("#fcc11b");
        setNavIconsColor("#fcc11b");

      }
    };

    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href="/"
              id="navbar-brand"
            >
              <img
                alt="SolarLink logo"
                src={navLogo}
                style={{ maxWidth: "200px" }}
              ></img>
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
              style={{ color: "#fbc11a" }}
            >
              <span className="navbar-toggler-bar top-bar" style={{ backgroundColor: navIconsColor }}></span>
              <span className="navbar-toggler-bar middle-bar" style={{ backgroundColor: navIconsColor }}></span>
              <span className="navbar-toggler-bar bottom-bar" style={{ backgroundColor: navIconsColor }}></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  href="/"
                >
                  <IcomoonReact iconSet={iconSet} color={navIconsColor} size={32} icon="home" style={{ paddingRight: "10px" }} />
                  <p>{t("navbar.home")}</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/contact"
                >
                  <IcomoonReact iconSet={iconSet} color={navIconsColor} size={32} icon="customer-service" style={{ paddingRight: "10px" }} />
                  <p>{t("navbar.contactUs")}</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="/faq"
                >
                  <IcomoonReact iconSet={iconSet} color={navIconsColor} size={32} icon="question" style={{ paddingRight: "10px" }} />
                  <p>{t("navbar.faqs")}</p>
                </NavLink>
              </NavItem>
              <UncontrolledDropdown nav>

                <DropdownToggle
                  caret
                  color="default"
                  href="/households-calculator"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <IcomoonReact iconSet={iconSet} color={navIconsColor} size={32} icon="calculator" style={{ paddingRight: "10px" }} />
                  <p>{t("navbar.calculators")}</p>
                </DropdownToggle>

                <DropdownMenu>

                  <DropdownItem to="/households-calculator" tag={Link}>
                    <IcomoonReact iconSet={iconSet} color={navIconsColor} size={32} icon="cascade-house" style={{ paddingRight: "10px" }} />
                    {t("navbar.basic")}
                  </DropdownItem>

                  <DropdownItem to="/industry-calculator" tag={Link}>
                    <IcomoonReact iconSet={iconSet} color={navIconsColor} size={32} icon="factory" style={{ paddingRight: "10px" }} />
                    {t("navbar.advanced")}
                  </DropdownItem>

                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem className="nav-platform">
                <Button
                  className="nav-link btn-neutral"
                  color="info"
                  href="https://platform.solarlink.app/"
                  id="upgrade-to-pro"
                >
                  <IcomoonReact iconSet={iconSet} color={navIconsColor} size={32} icon="devices" style={{ paddingRight: "10px" }} />
                  <p><b>{t("navbar.platform")}</b></p>
                </Button>
                <UncontrolledTooltip target="#upgrade-to-pro">
                  {t("navbar.goToPlatform")}
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <Dropdown style={{ position: "fixed", right: "0px", top: "0px" }}>
                  <Dropdown.Toggle id="dropdown-basic" style={{ background: "transparent" }}>
                    <GlobeIcon />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {languages.map((language) => (
                      <Dropdown.Item key={language.country_code} onClick={() => { i18n.changeLanguage(language.code); document.documentElement.setAttribute("lang", language.code); }}>
                        <Flag code={language.country_code} width="16px" height="16px" style={{ marginRight: "5px" }} />{language.name}</Dropdown.Item>

                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );

}

export default SiteNavbar;
