import { PDFDocument, StandardFonts } from 'pdf-lib';
import React, { Component, useEffect } from 'react';
import FileSaver, { saveAs } from 'file-saver';
import { useCurrentPng } from 'recharts-to-png';
import { useCallback } from 'react';
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
} from 'recharts';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${label}: ${Math.round(payload[0].value)} EUR`}</p>
                <p className="desc">Novcani tokovi u EUR.</p>
            </div>
        );
    }

    return null;
};


function CashFlowGraph(props) {

    // useCurrentPng usage (isLoading is optional)
    const [getPng, { ref, isLoading }] = useCurrentPng();

    useEffect(() => {
        const timer = setInterval(() => {
            if (setGraphDataToParent()) {
                clearInterval(timer);
            }
        }, 1000);
    }, []);

    const setGraphDataToParent = useCallback(async () => {
        const png = await getPng();
        props.setPNG(png);
    }, [getPng]);

    return (
        <>
            {props.data != null
                ?
                <ResponsiveContainer width="110%" height={400}>
                    <BarChart
                        ref={ref}
                        // width={500}
                        // height={400}
                        data={props.data}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="year" />
                        <YAxis />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend />
                        <ReferenceLine y={0} stroke="#000" />
                        <Bar dataKey="EUR" fill="#fcc11b" name="Novčani tokovi u eksploatacionom periodu u EUR" />
                    </BarChart>
                </ResponsiveContainer>
                : <>
                </>
            }
        </>
    );
}

export default CashFlowGraph;