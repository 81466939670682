import React from 'react';

// reactstrap components
// reactstrap components
import {
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Container,
    Row,
    Col,
} from "reactstrap";

// core components
import FAQHeader from 'components/FAQ/FAQHeader';
import SiteNavbar from "components/Navbars/SiteNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import HouseholdsCalculatorHeader from "components/HouseholdsCalculator/HouseholdsCalculatorHeader";

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import '.././assets/css/faq.css';
import { useTranslation } from 'react-i18next';

function FAQ(props) {

    const { t, i18n } = useTranslation();

    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {
        document.body.classList.add("basic-calculator");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        return function cleanup() {
            document.body.classList.remove("basic-calculator");
            document.body.classList.remove("sidebar-collapse");
        };
    }, []);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <SiteNavbar />
            <div className="wrapper">
                <FAQHeader />
                <div className="section" style={{ padding: "0" }} >
                    <Container>
                        <h2 className="title">{t('faqPage.mainTitle')}</h2>

                        <Col className='faq-fast-links' xs="12" sm="12" md="12" lg="3" xl="3">
                            <Row>
                                <a href='#opsta-pitanja'>
                                    1. {t('faqPage.generalQuestions.title')}
                                </a>
                            </Row>

                            <Row>
                                <a href='#kalkulatori'>
                                    2. {t('faqPage.calculators.title')}
                                </a>
                            </Row>

                            <Row>
                                <a href='#platforma'>
                                    3. {t('faqPage.platform.title')}
                                </a>
                            </Row>

                            <Row>
                                <a href='#procedure'>
                                    4. {t('faqPage.procedures.title')}
                                </a>
                            </Row>
                        </Col>

                        <Col xs="12" sm="12" md="12" lg="9" xl="9">
                            <div className='faq-card'>

                                <h3 id="opsta-pitanja">
                                    {t('faqPage.generalQuestions.title')}
                                </h3>

                                <Accordion expanded={expanded === 'panel1.1'} onChange={handleChange('panel1.1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography>
                                            {t('faqPage.generalQuestions.first.question')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='faq-answer'>
                                            {t('faqPage.generalQuestions.first.answer')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel1.2'} onChange={handleChange('panel1.2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                    >
                                        <Typography>
                                            {t('faqPage.generalQuestions.second.question')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='faq-answer'>
                                            {t('faqPage.generalQuestions.second.answer')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel1.3'} onChange={handleChange('panel1.3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <Typography>
                                            {t('faqPage.generalQuestions.third.question')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='faq-answer'>
                                            {t('faqPage.generalQuestions.third.answer')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                            </div>
                        </Col>

                        <Col xs="12" sm="12" md="12" lg="9" xl="9">
                            <div className='faq-card'>

                                <h3 id="kalkulatori">
                                    {t('faqPage.calculators.title')}
                                </h3>

                                <Accordion expanded={expanded === 'panel2.1'} onChange={handleChange('panel2.1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography>
                                            {t('faqPage.calculators.first.question')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='faq-answer'>
                                            {t('faqPage.calculators.first.answer')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel2.2'} onChange={handleChange('panel2.2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                    >
                                        <Typography>
                                            {t('faqPage.calculators.second.question')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='faq-answer'>
                                            {t('faqPage.calculators.second.answer')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel2.3'} onChange={handleChange('panel2.3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <Typography>
                                            {t('faqPage.calculators.third.question')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='faq-answer'>
                                            {t('faqPage.calculators.third.answer')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel2.4'} onChange={handleChange('panel2.4')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography>
                                            {t('faqPage.calculators.fourth.question')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='faq-answer'>
                                            {t('faqPage.calculators.fourth.answer')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel2.5'} onChange={handleChange('panel2.5')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                    >
                                        <Typography>
                                            {t('faqPage.calculators.fifth.question')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='faq-answer'>
                                            {t('faqPage.calculators.fifth.answer')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel2.6'} onChange={handleChange('panel2.6')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <Typography>
                                            {t('faqPage.calculators.sixth.question')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='faq-answer'>
                                            {t('faqPage.calculators.sixth.answer')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                            </div>
                        </Col>

                        <Col xs="12" sm="12" md="12" lg="9" xl="9">
                            <div className='faq-card'>

                                <h3 id="platforma">
                                    {t('faqPage.platform.title')}
                                </h3>

                                <Accordion expanded={expanded === 'panel3.1'} onChange={handleChange('panel3.1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography>
                                            {t('faqPage.platform.first.question')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='faq-answer'>
                                            {t('faqPage.platform.first.answer')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel3.2'} onChange={handleChange('panel3.2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                    >
                                        <Typography>
                                        {t('faqPage.platform.second.question')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='faq-answer'>
                                        {t('faqPage.platform.second.answer')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel3.3'} onChange={handleChange('panel3.3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <Typography>
                                        {t('faqPage.platform.third.question')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='faq-answer'>
                                        {t('faqPage.platform.third.answer')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel3.4'} onChange={handleChange('panel3.4')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <Typography>
                                        {t('faqPage.platform.fourth.question')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='faq-answer'>
                                        {t('faqPage.platform.fourth.answer')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel3.5'} onChange={handleChange('panel3.5')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <Typography>
                                        {t('faqPage.platform.fifth.question')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='faq-answer'>
                                        {t('faqPage.platform.fifth.answer')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel3.6'} onChange={handleChange('panel3.6')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <Typography>
                                        {t('faqPage.platform.sixth.question')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='faq-answer'>
                                        {t('faqPage.platform.sixth.answer')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                            </div>
                        </Col>

                        <Col xs="12" sm="12" md="12" lg="9" xl="9">
                            <div className='faq-card'>

                                <h3 id="procedure">
                                    {t('faqPage.procedures.title')}
                                </h3>

                                <Accordion expanded={expanded === 'panel4.1'} onChange={handleChange('panel4.1')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                    >
                                        <Typography>
                                        {t('faqPage.procedures.first.question')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='faq-answer'>
                                        {t('faqPage.procedures.first.answer')}     
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel4.2'} onChange={handleChange('panel4.2')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id="panel2bh-header"
                                    >
                                        <Typography>
                                        {t('faqPage.procedures.second.question')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='faq-answer'>
                                        {t('faqPage.procedures.second.answer')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                                <Accordion expanded={expanded === 'panel4.3'} onChange={handleChange('panel4.3')}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3bh-content"
                                        id="panel3bh-header"
                                    >
                                        <Typography>
                                        {t('faqPage.procedures.third.question')}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography className='faq-answer'>
                                        {t('faqPage.procedures.third.answer')}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>

                            </div>
                        </Col>

                    </Container>
                </div>
                <DefaultFooter />
            </div>
        </>
    );

}

export default FAQ;