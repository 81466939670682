import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import animationData from 'assets/lotties/calculators-animation.json';
import Lottie from "react-lottie";
import { useTranslation } from "react-i18next";

// core components

function Explanation() {

  const { t } = useTranslation();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <>
      <div className="section section-explanation">
        <Container>
          <Row>
            <Col lg="6" md="12">
              <div className="icons-container">
                {/* <Lottie
                  options={defaultOptions}
                /> */}
                <img alt="..." src={require("assets/img/Artboard 85.png")}></img>
              </div>
            </Col>
            <Col lg="6" md="12"
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignContent: "center"
              }}>
              <h2 className="title">{t("home.explanation.title")}</h2>
              <h5 className="description">
                {t("home.explanation.description")}
              </h5>
              {/* <Button
                className="btn-round mr-1"
                color="info"
                href="/households-calculator"
                size="lg"
                target="_blank"
              >
                {t("home.explanation.basic.calculator")}
              </Button> */}
              <Button
                className="btn-round"
                color="info"
                href="#"
                outline
                size="lg"
                style={{ cursor: "context-menu" }}
              >
                {t("home.explanation.button")}
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Explanation;
